import {
  Alert,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import * as yup from "yup";
import { useFormik } from "formik";
import "../styles/login.css";
import VerifyEmailOtp from "../components/Login/VerifyEmailOtp";
import { useDispatch } from "react-redux";
import { userlogin } from "../features/authSlice";
import { DeviceLimit } from "../components/Login/DeviceLimit";
import FirstPassword from "../components/Login/FirstPassword";

const validationSchema = yup.object({
  email: yup.string("Enter your email").required("Email is required").email(),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const Login = () => {
  const dispatch = useDispatch();
  // const { user, isLoading, isError, isSuccess, message } = useSelector(
  //   (state) => state.auth
  // );

  const [open, setOpen] = useState(false);
  const [openFp, setOpenFp] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [deviceLimiterOpen, setDeviceLimiterOpen] = useState({
    isOpen: false,
    payload: [],
  });

  const handleClickOpen = () => {
    if (formik.values.password.slice(0, 9) == "CLGENAUTO") {
      setOpenFp(true);
    } else {
      setOpen(true);
    }
  };

  const handleClickClose = () => {
    if (formik.values.password.slice(0, 9) === "CLGENAUTO") {
      setOpenFp(false);
    } else {
      setOpen(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      // handleClickOpen();
      //   alert(JSON.stringify(values, null, 2));
      setIsLoadingButton(true);
      dispatch(
        userlogin({
          email: values.email.toLowerCase(),
          password: values.password,
        })
      ).then((onResolved) => {
        if (onResolved.type === "auth/login/rejected") {
          setLoginFailed(true);
          if (onResolved.payload.errors[0]?.device_details) {
            setDeviceLimiterOpen({
              isOpen: true,
              payload: onResolved.payload.errors[0]?.device_details,
            });
          }
        } else {
          handleClickOpen();
        }
      });
      setTimeout(() => {
        actions.setSubmitting(false);
        setIsLoadingButton(false);
      }, 1000);
    },
  });

  const handleClose = () => {
    setDeviceLimiterOpen({ ...deviceLimiterOpen, isOpen: false });
    handleClickOpen();
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {/* <div className="title_container"> */}
        <p className="title">Sign In to CarbonLnk</p>
        {/* </div> */}
        <form onSubmit={formik.handleSubmit} className="form_container">
          <div className="logo_container">
            <img src="/images/carbonlnk-logo.svg" width="80" height="80" />
          </div>
          <div className="title_container">
            {/* <p className="title">Login to your Account</p> */}
            <span className="subtitle">Welcome to world of net zero.</span>
            <span className="subtitle">
              Get started with our app, just create an account and enjoy the
              experience.
            </span>
            {loginFailed && (
              <Alert severity="error">Login Failed Check Credentials</Alert>
            )}
          </div>
          <br />
          <div className="input_container">
            <TextField
              fullWidth
              size="small"
              autoFocus
              id="email"
              name="email"
              label="Email"
              color="grey"
              placeholder="yourmail@email.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              // className="input_field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon sx={{ color: "icon" }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="input_container">
            <TextField
              fullWidth
              size="small"
              id="password"
              name="password"
              label="Password"
              type="password"
              color="grey"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              className="input_field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon sx={{ color: "icon" }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <br />
          <Button
            variant="contained"
            fullWidth
            size="medium"
            type="submit"
            className="sign-in_btn"
            title="Sign In"
            color="button"
          >
            {isLoadingButton ? <CircularProgress size={25} /> : "Sign In"}
          </Button>

          {/* <p className="note">Terms of use &amp; Conditions</p> */}
        </form>
      </Box>
      <VerifyEmailOtp
        open={open}
        email={formik.values.email}
        forCP={false}
        onClose={handleClickClose}
      />
      <FirstPassword
        open={openFp}
        email={formik.values.email}
        onClose={handleClickClose}
      />
      <DeviceLimit
        id="device-limit"
        keepMounted
        open={deviceLimiterOpen.isOpen}
        onClose={handleClose}
        value={deviceLimiterOpen.payload}
        email={formik.values.email}
      />
    </>
  );
};

export default Login;

// import PropTypes from "prop-types";

// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import { useEffect, useState } from "react";
// import { Button, useMediaQuery } from "@mui/material";
// import { useDispatch } from "react-redux";
// import { billGet } from "../features/billSlice";
// // import TabOverview from "../components/GhgComp/TabOverview";
// // import TabGraph from "../components/GhgComp/TabGraph";

// const Login = () => {
//   const [value, setValue] = useState(0);
//   const [rowsData, setRowsData] = useState([]);

//   const ismobile = useMediaQuery("(max-width:600px)");
//   const dispatch = useDispatch();
//   useEffect(() => {
//     const isDark = localStorage.getItem("choosenTheme") === "dark";
//     isDark
//       ? document.documentElement.classList.add("dark")
//       : document.documentElement.classList.remove("dark");

//     // getBillsData();
//   }, []);

//   const getBillsData = () => {
//     dispatch(billGet({})).then((onResolved) => {
//       if (onResolved.type === "bill/get/fulfilled") {
//         setRowsData(onResolved.payload);
//       }
//     });
//   };

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <>
//       <Box width={"100%"} height={"5em"} marginBottom={3}>
//         {!ismobile && (
//           <Box position={"absolute"} right={"45%"} left={"50%"}>
//             <img
//               src="images/carbonlnk-logo.svg"
//               width={"144px"}
//               height={"60px"}
//             />
//           </Box>
//         )}
//       </Box>

//       <Box sx={{ width: "100%", ml: 3, display: "flex" }}>
//         {/* <div className="flex  flex-col">
//           <Tabs
//             aria-label="Options"
//             selectedKey={value}
//             onSelectionChange={setValue}
//             size="lg"
//           >
//             {[0, 1, 2, 4]?.map((e) => (
//               <Tab title={`Location: ${e}`}></Tab>
//             ))}
//           </Tabs>
//         </div> */}

//                    <Button label="Check" icon="pi pi-check" />

//       </Box>
//     </>
//   );
// };

// export default Login;
