import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  IconButton,
} from "@mui/material";
// import SDFilter from "../SDFilter";
import { ReactComponent as LocationIcon } from "../../../styles/icons/location_icon.svg";
import { ReactComponent as FacilityIcon } from "../../../styles/icons/facility_icon.svg";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import StyledMenuComp from "../MenuCompSD";
import { useDispatch } from "react-redux";
import { changeIndex } from "../../../features/sdSlice";

const FacilityVertical = ({ data }) => {
  const [openedIndex, setOpenedIndex] = useState();
  const [collapsedMenu, setCollapsedMenu] = useState();
  // const [data, setData] = useState(FacilityData);
  const [anchorEl, setAnchorEl] = useState({
    isOpen: null,
    isSubItem: null,
    isMain: null,
  });
  const open = Boolean(anchorEl.isOpen);

  const dispatch = useDispatch();

  const handleClickMenu = ({ event, isSubItem, dispatchData, isMain }) => {
    setAnchorEl({
      isOpen: event.currentTarget,
      isSubItem,
      isMain,
    });
    dispatch(changeIndex(dispatchData));
  };

  const handleClose = () => {
    setAnchorEl({ ...anchorEl, isOpen: null });
  };

  const handleClickOpenedIndex = (index) => {
    openedIndex === index ? setOpenedIndex(undefined) : setOpenedIndex(index);
    openedIndex !== index &&
      dispatch(
        changeIndex({
          countryIndex: parseInt(index),
          // facilityIndex: null,
          // sectionIndex: null,
          // floorIndex: null,
          // departmentIndex: null,
        })
      );
  };
  const handleClickCollapsedMenu = (index) => {
    setCollapsedMenu(index);
    const [, facilityIndex] = index?.toString()?.split(":");

    collapsedMenu !== index &&
      dispatch(
        changeIndex({
          facilityIndex: parseInt(facilityIndex),
          // sectionIndex: null,
          // floorIndex: null,
          // departmentIndex: null,
        })
      );
  };

  return (
    <>
      <Box
        width="26%"
        mb={1}
        minHeight="80vh"
        borderRight="2px solid"
        borderColor="#eeeeee"
        pr={1}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mt={1}
          alignItems="center"
        >
          <Typography variant="body1" color="initial" lineHeight={2}>
            Country & Facility
          </Typography>
          <IconButton
            size="small"
            sx={{ p: 0, m: 0, mr: 2 }}
            aria-label="more"
            onClick={(event) => {
              event.stopPropagation();
              handleClickMenu({
                event,
                isSubItem: false,
                isMain: true,
              });
            }}
          >
            <MoreHorizRoundedIcon color="disabled" />
          </IconButton>
        </Box>
        {/* <SDFilter  data={data} /> */}
        <List>
          {data.map((val, index) => (
            <>
              <ListItemButton onClick={() => handleClickOpenedIndex(index)}>
                <ListItemIcon>
                  <SvgIcon component={FacilityIcon} />
                </ListItemIcon>
                <ListItemText
                  primary={val?.key}
                  primaryTypographyProps={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                />
                <IconButton
                  size="small"
                  sx={{ p: 0, m: 0 }}
                  aria-label="more"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClickMenu({
                      event,
                      isSubItem: false,
                      dispatchData: {
                        countryIndex: parseInt(index),
                        // facilityIndex: null,
                        // sectionIndex: null,
                        // floorIndex: null,
                        // departmentIndex: null,
                      },
                    });
                  }}
                >
                  <MoreHorizRoundedIcon color="disabled" />
                </IconButton>
              </ListItemButton>
              <Collapse
                in={openedIndex === index ? true : false}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {val?.facility.map((value, cm_index) => (
                    <ListItemButton
                      sx={{
                        pl: 4,
                        bgcolor:
                          collapsedMenu === `${index}:${cm_index}` &&
                          "#eeeeee9c",
                      }}
                      onClick={() =>
                        handleClickCollapsedMenu(`${index}:${cm_index}`)
                      }
                      key={cm_index}
                    >
                      <ListItemIcon>
                        <SvgIcon component={LocationIcon} />
                      </ListItemIcon>
                      <ListItemText
                        primary={value.key}
                        primaryTypographyProps={{
                          textTransform: "capitalize",
                          fontWeight: "light",
                          letterSpacing: "0.15rem",
                        }}
                      />
                      <IconButton
                        size="small"
                        sx={{ p: 0, m: 0 }}
                        aria-label="more"
                        onClick={(event) =>
                          handleClickMenu({
                            event,
                            isSubItem: true,
                            dispatchData: {
                              facilityIndex: parseInt(cm_index),
                              // sectionIndex: null,
                              // floorIndex: null,
                              // departmentIndex: null,
                            },
                          })
                        }
                      >
                        <MoreHorizRoundedIcon color="disabled" />
                      </IconButton>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          ))}
        </List>
      </Box>
      <StyledMenuComp
        anchorEl={anchorEl.isOpen}
        isSubItem={anchorEl.isSubItem}
        isMain={anchorEl.isMain}
        open={open}
        handleClose={handleClose}
        subHeader1={"Country"}
        subHeader2={"Facility"}
      />
    </>
  );
};

export default FacilityVertical;
