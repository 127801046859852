import { createAsyncThunk } from "@reduxjs/toolkit";
import { encryptData } from "../components/utils/encrypt-decrypt";
import axios from "axios";
axios.defaults.withCredentials = true; // enable sending cookies
const FormData = require("form-data");

export const changeLogo = createAsyncThunk(
  "settings/logo",
  async ({ mediaPath }, thunkAPI) => {
    try {
      let data = new FormData();
      data.append("logo", mediaPath);

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/change/logo`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
        withCredentials: true,
      };
      const res = await axios.request(config);

      if (res.status === 200) {
        localStorage.setItem(
          "lsdvfsdfksmdvjkb",
          encryptData(JSON.stringify(res.data.logo))
        );

        return await res.status;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
