import { Box, Drawer } from "@mui/material";
import AddEntrySD from "./AddEntrySD";

const DrawerSD = ({ openDrawer, toggleDrawer, isEdit, method }) => {
  return (
    <Drawer
      anchor={"right"}
      open={openDrawer}
      onClose={() => toggleDrawer({ isOpen: false, isEdit })}
      sx={{ backdropFilter: "blur(5px)" }}
    >
      <Box p={4}>
        <AddEntrySD
          onClose={() => toggleDrawer({ isOpen: false, isEdit })}
          isEdit={isEdit}
          method={method}
        />
      </Box>
    </Drawer>
  );
};

export default DrawerSD;
