import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

// import BillUpload from "../components/Bills/BillUpload";
// import {
//   Button,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   useMediaQuery,
// } from "@mui/material";
// import BillList from "../components/Bills/BillList";
// import { useDispatch } from "react-redux";
// import { billDelete, billGet } from "../features/billSlice";
// import NotPresent from "../components/NotPresent";
// import { useNavigate } from "react-router-dom";
// import SnackBarFunction from "../components/SnackBarFunction";
// const Bills = () => {
//   const dispatch = useDispatch();
//   const [rowsData, setRowsData] = useState([]);
//   const [numSelected, setNumSelected] = useState([]);
//   const [open, setOpen] = useState({ isOpen: false, dispatchType: "add" });
//   const [errors, setErros] = useState({
//     loading: false,
//     severity: "error",
//     message: "Some Error Occured",
//   });
//   const navigate = useNavigate();
//   const ismobile = useMediaQuery("(max-width:600px)");

//   useEffect(() => {
//     getBill();
//   }, [open.isOpen === false]);

//   const getBill = () => {
//     dispatch(billGet({})).then((onResolved) => {
//       if (onResolved.type === "bill/get/fulfilled") {
//         setRowsData(onResolved.payload);
//       } else if (onResolved.payload === "unauthorized") {
//         navigate("/login");
//       }
//     });
//   };

//   // const handleClickOpen = () => {
//   //   setOpen({
//   //     ...open,
//   //     isOpen: true,
//   //   });
//   // };
//   const handleClose = () => {
//     setOpen({
//       ...open,
//       isOpen: false,
//     });
//   };

//   const getId = (prop) => {
//     setNumSelected(prop);
//   };

//   return (
//     <>
//       <Box width={"100%`"} height={"5em"}>
//         {!ismobile && (
//           <Box position={"absolute"} right={"45%"} left={"50%"}>
//             <img
//               src="images/carbonlnk-logo.svg"
//               width={"144px"}
//               height={"80px"}
//             />
//           </Box>
//         )}
//       </Box>
//       <Toolbar
//         sx={{
//           pl: { sm: 2 },
//           pr: 1,
//           pt: 5,
//           mb: 3,
//         }}
//       >
//         <>
//           <Typography
//             sx={{ flex: "1 1 100%", ml: 2 }}
//             variant="h6"
//             id="tableTitle"
//             component="div"
//             color={"#8A92A6"}
//           >
//             Bills
//             <Typography
//               sx={{ flex: "1 1 100%", fontWeight: 500 }}
//               variant="subtitle2"
//               id="tableTitle"
//               component="div"
//               color={"#8A92A6"}
//             >
//               Manage all your existing and new bills.
//             </Typography>
//           </Typography>
//           {numSelected.length > 0 ? (
//             <Box
//               sx={{
//                 display: "flex",
//                 // backgroundColor: "white",
//                 boxShadow:
//                   "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
//                 borderRadius: 1.5,
//                 marginRight: 4,
//                 width: 200,
//                 paddingX: 2,
//                 paddingY: 1,
//                 justifyContent: "space-between",
//               }}
//             >
//               <IconButton
//                 aria-label="edit"
//                 size="medium"
//                 onClick={() => {
//                   numSelected.map(() => {
//                     setOpen({ isOpen: true, dispatchType: "edit" });
//                   });
//                 }}
//               >
//                 <FontAwesomeIcon icon={faPen} size="xs" />
//               </IconButton>

//               <IconButton
//                 aria-label="clone"
//                 size="medium"
//                 onClick={() => {
//                   numSelected.map(() => {
//                     // handleClickOpen();
//                     setOpen({ isOpen: true, dispatchType: "clone" });
//                   });
//                 }}
//               >
//                 <FontAwesomeIcon icon={faClone} size="xs" />
//               </IconButton>
//               <IconButton
//                 aria-label="delete"
//                 size="medium"
//                 onClick={() => {
//                   numSelected.map((e) => {
//                     dispatch(billDelete({ id: e })).then((onResolved) => {
//                       if (onResolved.type === "bill/del/fulfilled") {
//                         const id = rowsData.findIndex((i) => i._id === e);

//                         rowsData.splice(id, 1);
//                         setRowsData([...rowsData]);
//                         setNumSelected([]);

//                         setErros({
//                           loading: true,
//                           severity: "success",
//                           message: "Bill Deleted",
//                         });
//                         setTimeout(() => {
//                           setErros({
//                             ...errors,
//                             loading: false,
//                           });
//                         }, 2000);
//                       } else {
//                         setErros({
//                           loading: true,
//                           severity: "error",
//                           message:
//                             onResolved.payload?.errors !== undefined
//                               ? onResolved.payload?.errors[0].message
//                               : "Some Error Occured,Tracking not started",
//                         });
//                         setTimeout(() => {
//                           setErros({
//                             ...errors,
//                             loading: false,
//                           });
//                         }, 2000);
//                       }
//                     });
//                   });
//                 }}
//               >
//                 <FontAwesomeIcon icon={faTrash} size="xs" />
//               </IconButton>
//             </Box>
//           ) : (
//             <Button
//               variant="contained"
//               startIcon={<AddIcon />}
//               size="large"
//               sx={{
//                 marginRight: 5,
//                 alignItems: "center",
//                 whiteSpace: "nowrap",
//                 px: 5,
//               }}
//               color="mainButton"
//               // disableElevation
//               onClick={() => {
//                 setOpen({ isOpen: true, dispatchType: "add" });
//               }}
//             >
//               Add New
//             </Button>
//           )}
//         </>
//       </Toolbar>
//       {billUploadFunction(
//         open.isOpen,
//         handleClose,
//         rowsData,
//         numSelected,
//         open.dispatchType
//       )}

//       {rowsData.length <= 0 ? (
//         // <Box sx={{ display: "flex", justifyContent: "center" }}>
//         <NotPresent />
//       ) : (
//         //</Box>
//         <BillList rows={rowsData} getId={getId} comp={"main"} />
//       )}
//       <SnackBarFunction
//         isLoadingButton={errors.loading}
//         severity={errors.severity}
//         message={errors.message}
//       />
//     </>
//   );
// };

// function billUploadFunction(
//   open,
//   handleClose,
//   rowsData,
//   numSelected,
//   dispatchType
// ) {
//   const id = numSelected.map((e) => {
//     return rowsData.findIndex((i) => i._id === e);
//   });
//   return (
//     <Dialog open={open} onClose={handleClose} fullScreen>
//       <DialogTitle component="h5">
//         <>
//           <span className="close" onClick={handleClose}>
//             X
//           </span>
//           <Box sx={{ marginLeft: 6 }}>
//             {dispatchType === "add"
//               ? "Add new Record"
//               : dispatchType === "clone"
//               ? "Clone Record"
//               : "Edit Record"}
//           </Box>
//         </>
//       </DialogTitle>
//       <DialogContent>
//         <BillUpload
//           rowsData={rowsData[id]}
//           dispatchType={dispatchType}
//           onClose={handleClose}
//         />
//       </DialogContent>
//     </Dialog>
//   );
// }
// export default Bills;

import { useMediaQuery } from "@mui/material";
import Devices from "../pages/Devices";
import Notification from "../pages/Notification";
import SideMenu from "../components/Bills/SideMenu";
import BTravel from "../components/Bills/MenuComponents/BTravel";
import BVehicles from "../components/Bills/MenuComponents/BVehicles";
import Electricity from "../components/Bills/MenuComponents/Electricity";
import StaffCommuting from "../components/Bills/MenuComponents/StaffCommuting";
import Transport from "../components/Bills/MenuComponents/Transport";
import Waste from "../components/Bills/MenuComponents/Waste";
import Water from "../components/Bills/MenuComponents/Water";
import Fuel from "../components/Bills/MenuComponents/Fuel";
import Materials from "../components/Bills/MenuComponents/Materials";
import RGas from "../components/Bills/MenuComponents/RGas";
// import { useContactsQuery } from "../features/billSlice";

const drawerWidth = 300;

const Bills = () => {
  const ismobile = useMediaQuery("(max-width:600px)");
  const [menu, setMenu] = useState("Busines Travel");

  const handleSideMenuItemClick = (event, index) => {
    setMenu(index);
  };

  // const { data, error, isLoading, isFetching, isSuccess } = useContactsQuery();
  // console.log(data, error, isLoading, isFetching, isSuccess);
  return (
    <>
      <Box width={"100%"} height={"5em"} bgcolor={"background.paper"}>
        {!ismobile && (
          <Box position={"absolute"} right={"45%"} left={"50%"}>
            <img
              src="images/carbonlnk-logo.svg"
              width={"144px"}
              height={"80px"}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{ display: "flex" }}
        bgcolor={"background.paper"}
        minHeight={"90vh"}
      >
        {SideMenuBox(handleSideMenuItemClick, menu)}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(90% - ${drawerWidth}px)` },
          }}
          ml={1}
          mr={4}
          maxWidth={"100vw"}
        >
          <Typography variant="h6" fontWeight={700} mb={2.8} ml={1.9}>
            {menu}
          </Typography>
          {menu === "Busines Travel" && <BTravel />}
          {menu === "Busines Vehicles" && <BVehicles />}
          {menu === "Electricity" && <Electricity />}
          {menu === "Fuels" && <Fuel />}
          {menu === "Materials" && <Materials />}
          {menu === "Refrigerant Gases" && <RGas />}
          {menu === "Staff Commuting" && <StaffCommuting />}
          {menu === "Transport" && <Transport />}
          {menu === "Waste" && <Waste />}
          {menu === "Water" && <Water />}
        </Box>
      </Box>
    </>
  );
};

export default Bills;

const SideMenuBox = (handleSideMenuItemClick, menu) => {
  return (
    <Box
      mx={4}
      // mt={1.5}
      mb={2}
      minWidth={"16rem"}
    >
      <Typography variant="h6" fontWeight={700} mb={3} ml={0.8}>
        Categories
      </Typography>
      <SideMenu
        title={"Busines Travel"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      />
      <SideMenu
        title={"Busines Vehicles"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      />
      <SideMenu
        title={"Electricity"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      />
      <SideMenu
        title={"Fuels"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      />
      {/* <SideMenu
        title={"Hotel Stay"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      /> */}
      <SideMenu
        title={"Materials"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      />
      {/* <SideMenu
        title={"Purchases"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      /> */}
      <SideMenu
        title={"Refrigerant Gases"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      />
      <SideMenu
        title={"Staff Commuting"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      />
      <SideMenu
        title={"Transport"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      />
      <SideMenu
        title={"Waste"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      />
      <SideMenu
        title={"Water"}
        footprint={"0.787"}
        sideMenuItemClick={handleSideMenuItemClick}
        selectedMenuItem={menu}
      />
    </Box>
  );
};
