import {
  Text,
  Flex,
  Bold,
  BarList,
  Col,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableHeaderCell,
} from "@tremor/react";
import { IconButton, SvgIcon } from "@mui/material";
import { ReactComponent as ArrowDownIcon } from "../../styles/icons/arrow_downIcon.svg";
import { ReactComponent as ArrowUpIcon } from "../../styles/icons/arrow_upIcon.svg";
import { useState } from "react";

const TGCategory = ({ filteredData, getId, subCategory }) => {
  const [subCategoryBtnIndex, setSubCategoryBtnIndex] = useState();

  return (
    <>
      <Col numColSpan={1} numColSpanLg={2}>
        <Flex className="py-4 mt-5">
          <Text>
            <Bold>Category</Bold>
          </Text>
          <Text>
            <Bold>Co2e kg</Bold>
          </Text>
        </Flex>
        <BarList
          data={filteredData}
          // showAnimation={true}
          // animationDuration={50000}
          className="mt-4"
        />
      </Col>
      <Table className="mt-5 ml-10 mb-1">
        <TableHead>
          <TableRow>
            <TableHeaderCell className="w-28 pl-0">Co2</TableHeaderCell>
            <TableHeaderCell className="w-28 pl-0">CH4</TableHeaderCell>
            <TableHeaderCell className="w-28 pl-0">N2O</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((item, index) => (
            <TableRow
              className="border-none"
              key={item.CO2}
              onClick={() => {
                subCategory === item.name ? getId("") : getId(item.name);
                setSubCategoryBtnIndex(index);
              }}
            >
              <TableCell className="p-0 pt-6">{item.CO2}</TableCell>
              <TableCell className="p-0 pt-6">
                <Text>{item.CH4}</Text>
              </TableCell>
              <TableCell className="p-0 pt-6 ">
                <div className="flex space-x-3 justify-between mr-3">
                  <span>{item.N2O}</span>
                  {subCategory && subCategoryBtnIndex === index ? (
                    <IconButton
                      size="small"
                      sx={{ p: 0, m: 0 }}
                      className={"w-1 shrink-0 rounded"}
                    >
                      <SvgIcon component={ArrowUpIcon} />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      sx={{ p: 0, m: 0 }}
                      className={"w-1 shrink-0 rounded"}
                    >
                      <SvgIcon component={ArrowDownIcon} />
                    </IconButton>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default TGCategory;
