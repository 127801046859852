import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import * as L from "leaflet";
import "leaflet-defaulticon-compatibility";
import { Typography, TextField, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";

const center = {
  lat: 51.507,
  lng: -0.08,
};
const CordinateSelctor = ({ forEdit, getCordinates }) => {
  const { value, index } = useSelector((state) => state.site_details);

  const [position, setPosition] = useState({
    lat: 51.507,
    lng: -0.08,
  });
  function DraggableMarker() {
    const [draggable, setDraggable] = useState(false);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setPosition(marker.getLatLng());
            getCordinates(marker.getLatLng());
          }
        },
      }),
      []
    );
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d);
    }, []);

    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        <Popup minWidth={90}>
          <span onClick={toggleDraggable}>
            {draggable
              ? "Marker is draggable"
              : "Click here to make marker draggable"}
          </span>
        </Popup>
      </Marker>
    );
  }
  useEffect(() => {
    setPosition({
      lat:
        value[index?.countryIndex]?.facility[index?.facilityIndex]?.section[
          index?.sectionIndex
        ]?.floor[index?.floorIndex]?.department[index?.departmentIndex]
          ?.cordinates[0]?.lat ?? 51.507,
      lng:
        value[index?.countryIndex]?.facility[index?.facilityIndex]?.section[
          index?.sectionIndex
        ]?.floor[index?.floorIndex]?.department[index?.departmentIndex]
          ?.cordinates[0]?.lng ?? -0.08,
    });
  }, [index.departmentIndex]);

  return (
    <>
      {!forEdit && (
        <Typography variant="body1" color="initial" lineHeight={2}>
          Location Picker
        </Typography>
      )}
      {/* <Typography variant="body2" color="initial">
        Latitude: {position.lat}
      </Typography> */}
      <Box display="flex" gap={2} my={2}>
        <TextField
          id="Latitude"
          label="Latitude"
          value={position.lat}
          size="small"
          disabled
        />
        <TextField
          id="Langittude"
          label="Langittude"
          value={position.lng}
          size="small"
          disabled
        />
      </Box>

      <MapContainer
        center={center}
        zoom={13}
        scrollWheelZoom={false}
        style={{ height: forEdit ? "40vh" : "100vh", width: "100wh" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {forEdit ? <DraggableMarker /> : <Marker position={position}></Marker>}
      </MapContainer>
    </>
  );
};

export default CordinateSelctor;
