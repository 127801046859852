import { Typography } from "@mui/material";
import SelectField from "../FormField/SelectField";

export default function TypeofElec(props) {
  const {
    formField: { elecTypeValue },
  } = props;
  const options = [
    {
      value: "renewable",
      label: "renewable",
    },
    {
      value: "non-renewable",
      label: "non-renewable",
    },
  ];
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Electricity Type
      </Typography>
      <SelectField
        name={elecTypeValue.name}
        label={elecTypeValue.label}
        data={options}
        fullWidth
      />
    </>
  );
}
