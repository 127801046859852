import { Box } from "@mui/material";
import {
  BarChart,
  Card,
  Divider,
  Select,
  SelectItem,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import { useGHGDepartment } from "../../../features/billSlice";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useRef, useState } from "react";
import { useEffect } from "react";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

const valueFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()}`;

const ByDepartment = () => {
  const refSC = useRef(null);
  const [dateValueFrom, setDateValueFrom] = useState(dayjs("2020-01-02"));
  const [dateValueTo, setDateValueTo] = useState(dayjs("2027-06-09"));
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [subCategoryIndex, setSubCategoryIndex] = useState(null);

  const {
    data: tabs = [],
    isError: isLoadingError,
    isFetching: isFetchingBills,
    isLoading: isLoadingBills,
    refetch,
  } = useGHGDepartment({
    from: dateValueFrom !== "" ? dateValueFrom : dayjs("2020-01-02"),
    to: dateValueTo !== "" ? dateValueTo : dayjs("2027-06-09"),
    facility: selectedFacility.length === 0 ? undefined : [selectedFacility],
  });

  useEffect(() => {
    refetch();
  }, [dateValueFrom, dateValueTo, selectedFacility]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", m: 2 }}>
      <Card className="p-0">
        <div className="p-6 flex flex-row justify-between items-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="flex flex-row gap-4 ">
              <DatePicker
                label="From"
                className="max-w-xs bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                views={["month", "year"]}
                maxDate={dayjs(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 1,
                    1
                  )
                )}
                value={dateValueFrom}
                onChange={(newValue) => setDateValueFrom(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    InputProps: {
                      disableUnderline: true,
                      sx: { "& fieldset": { border: "none" } },
                    },
                    InputLabelProps: {
                      sx: { color: "grey" },
                    },
                  },
                }}
                sx={{ paddingX: 0.8, paddingY: 0.6 }}
              />

              <DatePicker
                label="To"
                className="max-w-xs bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                views={["month", "year"]}
                value={dateValueTo}
                onChange={(newValue) => setDateValueTo(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    InputProps: {
                      disableUnderline: true,
                      sx: { "& fieldset": { border: "none" } },
                    },
                    InputLabelProps: {
                      sx: { color: "grey" },
                    },
                  },
                }}
                sx={{ paddingX: 0.8, paddingY: 0.6 }}
              />
            </div>
          </LocalizationProvider>
          <Select
            onValueChange={setSelectedFacility}
            placeholder="Facility Selection"
            className="max-w-xs"
          >
            {tabs
              .map((tab) => tab.name)
              .map((facility, index) => (
                <SelectItem key={index} value={facility}>
                  {facility}
                </SelectItem>
              ))}
          </Select>
          <div className="hidden md:flex md:items-center md:space-x-2">
            <span
              className="shrink-0 animate-pulse rounded-tremor-full bg-emerald-500/30 p-1"
              aria-hidden={true}
            >
              <span className="block h-2 w-2 rounded-tremor-full bg-emerald-500" />
            </span>
            <p className="mt-4 text-tremor-default text-tremor-content dark:text-dark-tremor-content md:mt-0">
              Updated just now
            </p>
          </div>
        </div>
        <div className="border-t border-tremor-border p-6 dark:border-dark-tremor-border">
          <TabGroup>
            <div className="md:flex md:items-center md:justify-between">
              <TabList
                variant="line"
                className="w-full rounded-tremor-small md:w-60"
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.name}
                    className="w-full justify-center ui-selected:text-tremor-content-strong ui-selected:dark:text-dark-tremor-content-strong"
                  >
                    {tab.name}
                  </Tab>
                ))}
              </TabList>
            </div>
            <TabPanels>
              {tabs.map((tab) => (
                <TabPanel key={tab.name}>
                  <ul
                    role="list"
                    className="mt-6 flex flex-wrap gap-x-20 gap-y-10"
                  >
                    {tab.summary.map((item, index) => (
                      <li
                        key={item.name}
                        className="cursor-pointer"
                        onClick={() => {
                          setSubCategoryIndex(index);
                          refSC.current?.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        <div className="flex items-center space-x-2">
                          <span
                            className={
                              "h-3 w-3 shrink-0 rounded-sm bg-blue-300"
                            }
                            aria-hidden={true}
                          />
                          <p className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                            {valueFormatter(item.total)}
                          </p>
                        </div>
                        <p className="whitespace-nowrap text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                          {item.name}
                        </p>
                      </li>
                    ))}
                  </ul>
                  <BarChart
                    data={tab.data}
                    index="category"
                    categories={["CO2e", "CO2", "CH4", "N2O"]}
                    colors={["blue", "cyan", "violet", "amber"]}
                    // stack={true}
                    showAnimation={true}
                    showLegend={false}
                    yAxisWidth={42}
                    valueFormatter={valueFormatter}
                    className="mt-10 hidden h-72 md:block"
                  />
                  <BarChart
                    data={tab.data}
                    index="category"
                    categories={["CO2e", "CO2", "CH4", "N2O"]}
                    colors={["blue", "cyan", "violet", "amber"]}
                    // stack={true}
                    showAnimation={true}
                    showLegend={false}
                    showYAxis={false}
                    valueFormatter={valueFormatter}
                    className="mt-6 h-72 md:hidden"
                  />
                  <Divider />

                  <h3 className="ml-1 mr-1 font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    Sub Category overview
                  </h3>
                  <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Click On Legend to Expand Details.
                  </p>

                  {subCategoryIndex !== null && (
                    <div ref={refSC}>
                      <BarChart
                        data={tab.data[subCategoryIndex]?.sub_category ?? []}
                        index="sub_category"
                        categories={["CO2e", "CO2", "CH4", "N2O"]}
                        colors={["blue", "cyan", "violet", "amber"]}
                        // stack={true}
                        showAnimation={true}
                        showLegend={false}
                        yAxisWidth={42}
                        valueFormatter={valueFormatter}
                        className="mt-10 hidden h-72 md:block"
                      />
                      <BarChart
                        data={tab.data[subCategoryIndex]?.sub_category ?? []}
                        index="sub_category"
                        categories={["CO2e", "CO2", "CH4", "N2O"]}
                        colors={["blue", "cyan", "violet", "amber"]}
                        // stack={true}
                        showAnimation={true}
                        showLegend={false}
                        showYAxis={false}
                        valueFormatter={valueFormatter}
                        className="mt-6 h-72 md:hidden"
                      />
                    </div>
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </TabGroup>
        </div>
      </Card>
    </Box>
  );
};

export default ByDepartment;
