import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  ListSubheader,
} from "@mui/material";
import { useState } from "react";
import DrawerSD from "./Drawer/Drawer_sd";
import { useDispatch } from "react-redux";
import { sdDelete } from "../../features/sdSlice";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledMenuComp = ({
  anchorEl,
  isSubItem,
  isMain,
  open,
  handleClose,
  subHeader1,
  subHeader2,
}) => {
  const [openDrawer, setOpenDrawer] = useState({
    isOpen: false,
    isEdit: undefined,
    isSecondSection: undefined,
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const toggleDrawer = ({ isOpen, isEdit, isSecondSection }) => {
    setOpenDrawer({ isOpen, isEdit, isSecondSection });
  };

  const dispatch = useDispatch();

  return (
    <>
      <StyledMenu
        id="sd-menu"
        MenuListProps={{
          "aria-labelledby": "sd-menu",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!isSubItem && <ListSubheader>{subHeader1}</ListSubheader>}
        {isMain ? (
          <MenuItem
            onClick={() => {
              toggleDrawer({
                isOpen: true,
                isEdit: false,
                isSecondSection: false,
              });
              handleClose();
            }}
            disableRipple
          >
            <AddRoundedIcon />
            Add
          </MenuItem>
        ) : (
          <div>
            <MenuItem
              // disabled
              onClick={() => {
                toggleDrawer({
                  isOpen: true,
                  isEdit: true,
                  isSecondSection: false,
                });
                handleClose();
              }}
              disableRipple
            >
              <EditIcon />
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClickOpenDeleteDialog();
                handleClose();
              }}
              disableRipple
            >
              <DeleteRoundedIcon />
              Delete
            </MenuItem>
            {!isSubItem && (
              <div>
                <ListSubheader>{subHeader2}</ListSubheader>
                <MenuItem
                  onClick={() => {
                    toggleDrawer({
                      isOpen: true,
                      isEdit: false,
                      isSecondSection: true,
                    });
                    handleClose();
                  }}
                  disableRipple
                >
                  <AddRoundedIcon />
                  Add
                </MenuItem>
              </div>
            )}
          </div>
        )}
      </StyledMenu>
      <DrawerSD
        openDrawer={openDrawer.isOpen}
        toggleDrawer={toggleDrawer}
        isEdit={openDrawer.isEdit}
        method={
          isSubItem || openDrawer.isSecondSection
            ? subHeader2?.toLowerCase()
            : subHeader1?.toLowerCase()
        }
      />
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-site"
      >
        <DialogTitle id="delete-site">Do you want to Delete ?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Disagree</Button>
          <Button
            onClick={() => {
              dispatch(
                sdDelete({
                  method: isSubItem
                    ? subHeader2?.toLowerCase()
                    : subHeader1?.toLowerCase(),
                })
              );
              handleCloseDeleteDialog();
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StyledMenuComp;
