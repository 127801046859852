import { createAsyncThunk } from "@reduxjs/toolkit";

export const temperatureDeviceGet = createAsyncThunk(
  "temperatureDevice/get",
  async ({}, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/handle/temperaturedevices`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        return data.user.temperatureDevices;
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      // const message =
      //     (error.response && error.response.data && error.response.data.message) ||
      //     error.message ||
      //     error.toString();
      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const temperatureDeviceAdd = createAsyncThunk(
  "temperatureDevice/add",
  async (
    {
      deviceNickName,
      deviceGateway,
      deviceSerialNumber,
      deviceModel,
      deviceChannel,
      location,
    },
    thunkAPI
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/handle/temperaturedevices`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            deviceNickName,
            deviceGateway,
            deviceSerialNumber,
            deviceModel,
            deviceChannel,
            location,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      //   const message =
      //     (error.response &&
      //       error.response.data &&
      //       error.response.data.message) ||
      //     error.message ||
      //     error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const temperatureDeviceUpdate = createAsyncThunk(
  "temperatureDevice/update",
  async (
    {
      id,
      deviceNickName,
      deviceGateway,
      deviceSerialNumber,
      deviceModel,
      deviceChannel,
      location,
    },
    thunkAPI
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/handle/temperaturedevices`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            id,
            deviceNickName,
            deviceGateway,
            deviceSerialNumber,
            deviceModel,
            deviceChannel,
            location,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const temperatureDeviceDelete = createAsyncThunk(
  "temperatureDevice/del",
  async ({ id }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/handle/temperaturedevices`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            id,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);
