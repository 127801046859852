import { Typography } from "@mui/material";
import InputField from "../FormField/InputField";

export default function DeviceNickName(props) {
  const {
    formField: { DeviceNickName },
  } = props;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Device Nick Name
      </Typography>
      <InputField
        name={DeviceNickName.name}
        label={DeviceNickName.label}
        fullWidth
        type="text"
        autoFocus
      />
    </>
  );
}
