import CryptoJS from "crypto-js";

const encryptData = (text) => {
  const secretPass = process.env.REACT_APP_CRYPTO_KEY;
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    secretPass
  ).toString();
  return data;
};

const decryptData = (text) => {
  if (text !== null) {
    const secretPass = process.env.REACT_APP_CRYPTO_KEY;

    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  }
  return null;
};

export { encryptData, decryptData };
