import { forwardRef, useState } from "react";
import "../../App.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { topicUnSubscribe, topicSubscribe } from "../../features/topicSlice";
import { trackingUpdate } from "../../features/deviceSlice";
import SnackBarFunction from "../SnackBarFunction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSterlingSign, faClock } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as yup from "yup";
const validationSchema = yup.object({
  costDay: yup.number("Enter your ").required("Is required"),
  costNight: yup.number("Enter your ").required("Is required"),
});

const DeviceSwitch = ({ isOn, data }) => {
  const [checked, setChecked] = useState(isOn);
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  });
  const [trackingConfirmopen, setTrackingConfirmOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    data?.peakTimeStart && new Date(2023, 0, 0, data?.peakTimeStart)
  );
  const [endDate, setEndDate] = useState(
    data?.peakTimeEnd && new Date(2023, 0, 0, data?.peakTimeEnd)
  );

  const dispatch = useDispatch();
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const trackingConfirmHandleClose = () => {
    setTrackingConfirmOpen(false);
  };
  const CustomeDateComponent = forwardRef(({ value, onClick }, ref) => (
    <button
      style={{
        // marginRight: 100,
        // marginLeft: 100,
        minWidth: 100,
        borderRadius: 5,
        border: "1px solid grey",
      }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));
  const formik = useFormik({
    initialValues: {
      costDay: data?.costDay,
      costNight: data?.costNight,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (!checked) {
        dispatch(
          topicSubscribe({
            topic:
              data.deviceGateway === undefined ||
              data.deviceGateway === "" ||
              data.deviceGateway === null
                ? [`${data.deviceModel}/status/em:0`]
                : [`/gw/acrelHW/AWT100/data/${data.deviceSerialNumber}`],
            costDay: [values.costDay],
            costNight: [values.costNight],
            peakTimeStart: [startDate.getHours()],
            peakTimeEnd: [endDate.getHours()],
            id: data._id,
          })
        ).then((onResolved) => {
          if (onResolved.type === "topic/sub/fulfilled") {
            dispatch(
              trackingUpdate({ id: data._id, trackingstatus: true })
            ).then((onResolved) => {
              if (onResolved.type === "device/track/fulfilled") {
                setChecked(true);
                trackingConfirmHandleClose();
                setErros({
                  loading: true,
                  severity: "success",
                  message: "Tracking Started",
                });
                setTimeout(() => {
                  setErros({
                    ...errors,
                    loading: false,
                  });
                }, 2000);
              }
            });
          } else {
            setErros({
              loading: true,
              severity: "error",
              message:
                onResolved.payload?.errors !== undefined
                  ? onResolved.payload?.errors[0].message
                  : "Some Error Occured,Tracking not started",
            });
            setTimeout(() => {
              setErros({
                ...errors,
                loading: false,
              });
            }, 2000);
          }
        });
      } else {
        dispatch(
          topicUnSubscribe({
            topic:
              data.deviceGateway === undefined ||
              data.deviceGateway === "" ||
              data.deviceGateway === null
                ? [`${data.deviceModel}/status/em:0`]
                : [`/gw/acrelHW/AWT100/data/${data.deviceSerialNumber}`],
          })
        ).then((onResolved) => {
          if (onResolved.type === "topic/unsub/fulfilled") {
            dispatch(
              trackingUpdate({ id: data._id, trackingstatus: false })
            ).then((onResolved) => {
              if (onResolved.type === "device/track/fulfilled") {
                setChecked(false);
                trackingConfirmHandleClose();
                setErros({
                  loading: true,
                  severity: "success",
                  message: "Tracking Stopped",
                });
                setTimeout(() => {
                  setErros({
                    ...errors,
                    loading: false,
                  });
                }, 2000);
              }
            });
          } else {
            setErros({
              loading: true,
              severity: "error",
              message:
                onResolved.payload?.errors !== undefined
                  ? onResolved.payload?.errors[0].message
                  : "Some Error Occured,UnTracking not done",
            });
            setTimeout(() => {
              setErros({
                ...errors,
                loading: false,
              });
            }, 2000);
          }
        });
      }
    },
  });

  return (
    <>
      <AntSwitch
        checked={checked}
        onChange={() => setTrackingConfirmOpen(true)}
        inputProps={{ "aria-label": "ant design" }}
      />
      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
      <Dialog
        open={trackingConfirmopen}
        onClose={trackingConfirmHandleClose}
        aria-labelledby="alert-tracking"
        aria-describedby="alert-tracking-description"
      >
        <form id="subUnsub" onSubmit={formik.handleSubmit}>
          <DialogTitle
            id="alert-tracking"
            // sx={{  mb: 2, color: "white" }}
          >
            {"Do you want to Change Tracking Status?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-tracking-description">
              If you change Status of this device, all devices with this Gateway
              and Device ID got stop Tracking .
            </DialogContentText>
            <DialogContentText
              sx={{
                fontWeight: "bold",
                fontSize: "body1.fontSize",
                bgcolor: "#f3bdc6",
                mt: 2,
                color: "#bb171c",
                p: 1,
                borderRadius: 2,
                // fontFamily:""
              }}
              id="alert-tracking-description"
            >
              Dont Touch If you Dont know what is this!!!
            </DialogContentText>
            {!checked && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    mt: 2,
                    gap: 2,
                    justifyContent: "center",
                    border: "2px dashed rgb(171, 202, 255)",
                    borderRadius: 2,
                    padding: 2,
                  }}
                >
                  <TextField
                    size="small"
                    id="costDay"
                    name="costDay"
                    label="Cost of Electricity at Day"
                    type="number"
                    color="icon"
                    value={formik.values.costDay}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.costDay && Boolean(formik.errors.costDay)
                    }
                    helperText={formik.touched.costDay && formik.errors.costDay}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faSterlingSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    size="small"
                    id="costNight"
                    name="costNight"
                    label="Cost of Electricity at Night"
                    value={formik.values.costNight}
                    type="number"
                    color="icon"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.costNight &&
                      Boolean(formik.errors.costNight)
                    }
                    helperText={
                      formik.touched.costNight && formik.errors.costNight
                    }
                    className="input_field"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faSterlingSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    mt: 2,
                    border: "2px dashed rgb(171, 202, 255)",
                    borderRadius: 2,
                    padding: 2,
                    gap: 3,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle2">Peak Time Start</Typography>
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    required
                    form="subUnsub"
                    customInput={<CustomeDateComponent />}
                  />
                  <Typography variant="subtitle2" ml={5}>
                    Peak Time End
                  </Typography>
                  <DatePicker
                    showIcon
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    required
                    form="subUnsub"
                    customInput={<CustomeDateComponent />}
                  />
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="button" onClick={trackingConfirmHandleClose}>
              Disagree
            </Button>
            <Button color="error" type="submit" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DeviceSwitch;
