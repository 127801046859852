import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from "material-react-table";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Slide,
  SvgIcon,
  Typography,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as TrashIcon } from "../../../styles/icons/trash.svg";
import { ReactComponent as EditAltIcon } from "../../../styles/icons/pencil_alt.svg";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FacilityAddress } from "./FacilityAddress";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  useGetBills,
  useCreateBill,
  useUpdateBill,
  useDeleteBill,
} from "../../../features/billSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSites } from "../../../features/sdSlice";

const BTravelList = ({ props }) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [openTopBar, setOpenTopBar] = useState(false);

  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  //call READ hook
  const {
    data: fetchedData = [],
    isError: isLoadingError,
    isFetching: isFetchingBills,
    isLoading: isLoadingBills,
  } = useGetBills({
    category: props.category,
  });
  // console.log(
  //   fetchedData,
  //   isLoadingError,
  //   isFetchingBills,
  //   isLoadingBills
  // );
  //call CREATE hook
  const {
    mutateAsync: createBill,
    isPending: isCreatingBill,
    isSuccess: isCreatedBill,
  } = useCreateBill({
    category: props.category,
  });
  //call UPDATE hook
  const {
    mutateAsync: updateBill,
    isPending: isUpdatingBill,
    isSuccess: isUpdatedBill,
  } = useUpdateBill();
  //call DELETE hook
  const {
    mutateAsync: deleteBill,
    isPending: isDeletingBill,
    isSuccess: isDeletedBill,
  } = useDeleteBill();

  //CREATE action
  const handleCreateBill = async ({ values, table }) => {
    const newValidationErrors = validateInputData(values);

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createBill(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleUpdateBill = async ({ values, table, row }) => {
    const newValidationErrors = validateInputData(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateBill({ values, id: row.original._id });
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this bill?")) {
      deleteBill(row.original._id);
    }
  };

  const subtotal = useMemo(
    () =>
      fetchedData
        .filter(
          (e) => new Date(e.from) <= lastDay && new Date(e.from) >= firstDay
        )
        .map(({ cost }) => parseInt(cost))
        .reduce((sum, i) => sum + i, 0),
    [isFetchingBills, isCreatedBill, isUpdatedBill, isDeletedBill]
  );

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "_id",
      //   header: "_id",
      //   enableEditing: false,
      //   size: 80,
      // },
      {
        accessorFn: (row) => new Date(row.from), //accessorFn used to join multiple data into a single cell
        id: "from", //id is still required when using accessorFn instead of accessorKey
        header: "From",
        filterFn: "greaterThanOrEqualTo",
        Cell: ({ cell }) => moment(cell?.getValue()).format("DD-MMM-YYYY"), //render Date as a string
        // Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        Edit: ({ column, row }) => {
          const onBlur = (event) => {
            row._valuesCache[column.id] = event.$d;
          };
          //remove any previous validation errors when user focuses on the input
          return (
            <>
              <DatePicker
                onChange={onBlur}
                onAccept={() =>
                  setValidationErrors({
                    ...validationErrors,
                    from: undefined,
                  })
                }
                defaultValue={dayjs(row._valuesCache.from)}
                slotProps={{ textField: { size: "small" } }}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    fontSize: "0.8rem",
                  },
                }}
              />
              <Typography variant="caption" color="error">
                {validationErrors?.from}
              </Typography>
            </>
          );
        },
        Filter: ({ column }) => (
          <DatePicker
            slotProps={{ textField: { size: "small" } }}
            selected={column.getFilterValue()}
            onChange={(newValue) => {
              column.setFilterValue(new Date(newValue));
            }}
            renderYearContent={renderYearContent}
            sx={{
              "& .MuiOutlinedInput-input": {
                fontSize: "0.8rem",
              },
            }}
          />
        ),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.from,
          helperText: validationErrors?.from,
          type: "date",
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorFn: (row) => new Date(row.to),
        id: "to",
        header: "To",
        filterFn: "lessThanOrEqualTo",
        Cell: ({ cell }) => moment(cell?.getValue()).format("DD-MMM-YYYY"), //render Date as a string
        Edit: ({ column, row }) => {
          const onBlur = (event) => {
            row._valuesCache[column.id] = event.$d;
            // if (isCreating) {
            // setCreatingRow(row);
            // } else if (isEditing) {
            // table.setEditingRow(row);
            // }
          };
          return (
            <>
              <DatePicker
                onChange={onBlur}
                onAccept={() =>
                  setValidationErrors({
                    ...validationErrors,
                    to: undefined,
                  })
                }
                defaultValue={dayjs(row._valuesCache.from)}
                slotProps={{ textField: { size: "small" } }}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    fontSize: "0.8rem",
                  },
                }}
              />
              <Typography variant="caption" color="error">
                {validationErrors?.to}
              </Typography>
            </>
          );
        },
        Filter: ({ column }) => (
          <DatePicker
            slotProps={{ textField: { size: "small" } }}
            selected={column.getFilterValue()}
            onChange={(newValue) => {
              column.setFilterValue(new Date(newValue));
            }}
            renderYearContent={renderYearContent}
            sx={{
              "& .MuiOutlinedInput-input": {
                fontSize: "0.8rem",
              },
            }}
          />
        ),
        enableGlobalFilter: false, // do not scan this column during global filtering
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.to,
          helperText: validationErrors?.to,
          type: "date",
          //remove any previous validation errors when user focuses on the input
          // onFocus: () =>
          //   setValidationErrors({
          //     ...validationErrors,
          //     to: undefined,
          //   }),
        },
      },
      {
        accessorKey: "sub_category",
        header: props.sub_category,
        // editVariant: "select",
        // editSelectOptions: props.sub_category_list,
        Edit: ({ column, row }) => {
          const onBlur = (event) => {
            row._valuesCache[column.id] = event.target.value;
            // if (isCreating) {
            // setCreatingRow(row);
            // } else if (isEditing) {
            // table.setEditingRow(row);
            // }
            setValidationErrors({
              ...validationErrors,
              sub_category: undefined,
            });
          };
          return (
            <>
              <Select
                labelId="sub_category_list-select-label"
                id="sub_category_list-select"
                // value={age}
                // label="Age"
                defaultValue={row._valuesCache.sub_category}
                color="icon"
                onChange={onBlur}
                fullWidth
                size="small"
                onSubmit={() =>
                  setValidationErrors({
                    ...validationErrors,
                    sub_category: undefined,
                  })
                }
              >
                {props.sub_category_list.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption" color="error">
                {validationErrors?.sub_category}
              </Typography>
            </>
          );
        },
        // muiEditTextFieldProps: {
        //   required: true,
        //   error: !!validationErrors?.sub_category,
        //   helperText: validationErrors?.sub_category,
        //   //remove any previous validation errors when user focuses on the input
        //   onFocus: () =>
        //     setValidationErrors({
        //       ...validationErrors,
        //       sub_category: undefined,
        //     }),
        // },
      },
      {
        accessorKey: "identifier",
        header: props.sub_category_identifier,
        muiEditTextFieldProps: {
          required: true,
          variant: "outlined",
          error: !!validationErrors?.identifier,
          helperText: validationErrors?.identifier,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              identifier: undefined,
            }),
        },
      },
      {
        accessorKey: "consumed_value",
        header: props.consumed_value,
        Cell: ({ renderedCellValue }) => (
          <>
            <Typography variant="subtitle2">
              {renderedCellValue}
              <Box component="span" fontSize={11}>
                <Typography
                  variant="overline"
                  fontSize={6}
                  // letterSpacing={0}
                  color="text.tableHead"
                >
                  {/* ({row?.original.unit}km) */}
                  {props.unit}
                </Typography>
              </Box>
            </Typography>
          </>
        ),
        muiEditTextFieldProps: {
          required: true,
          type: "number",
          variant: "outlined",
          error: !!validationErrors?.consumed_value,
          helperText: validationErrors?.consumed_value,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              consumed_value: undefined,
            }),
        },
      },
      {
        accessorKey: "cost",
        header: "Cost (£)",
        Footer: ({ renderedCellValue, row }) => {
          return (
            <Stack>
              <>
                Total Cost Current Month:
                <Box color="warning.main">
                  {subtotal?.toLocaleString?.("en-GB", {
                    style: "currency",
                    currency: "GBP",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </Box>
              </>
            </Stack>
          );
        },
        muiEditTextFieldProps: {
          required: true,
          type: "number",
          variant: "outlined",
          error: !!validationErrors?.cost,
          helperText: validationErrors?.cost,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              cost: undefined,
            }),
        },
      },
      {
        accessorKey: "location",
        header: "Location",
        Cell: ({ renderedCellValue }) => (
          <>
            <FacilityAddress
              renderedCellValue={renderedCellValue}
              // data={row.original}
            />
          </>
        ),
        Edit: ({ column, row }) => {
          const dispatch = useDispatch();
          const { value, isLoading } = useSelector(
            (state) => state.site_details
          );
          value.length < 1 && dispatch(getSites());

          const onBlur = (event) => {
            row._valuesCache[column.id] = event.target.value;
            // if (isCreating) {
            // setCreatingRow(row);
            // } else if (isEditing) {
            // table.setEditingRow(row);
            // }
            setValidationErrors({
              ...validationErrors,
              location: undefined,
            });
          };

          const departments = getDepartments(value);

          return (
            <>
              <Select
                labelId="facility_list-select-label"
                id="facility_list-select"
                color="icon"
                defaultValue={row._valuesCache?.location?._id}
                onChange={onBlur}
                fullWidth
                size="small"
              >
                {departments.map((value, index) => (
                  <MenuItem key={index} value={value._id}>
                    {value.key}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption" color="error">
                {validationErrors?.location}
              </Typography>
            </>
          );
        },
      },
    ],
    [validationErrors, subtotal]
  );

  const table = useMaterialReactTable({
    columns,
    data: fetchedData,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enablePinning: true,
    enableMultiSort: true,
    initialState: {
      columnVisibility: { identifier: props.columnVisibility },
      pagination: { pageSize: 5 },
    }, //hide firstName column by default
    // columnOrder: [
    //   'name',
    //   'email',
    //   'phone',
    //   'city',
    //   'country',
    //   'mrt-row-select', //move the built-in selection column to the end of the table
    // ],
    // enableRowActions
    // enableRowSelection: true,
    positionActionsColumn: "last",
    enableColumnDragging: false,
    getRowId: (row) => row._id,
    muiToolbarAlertBannerProps: isLoadingError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateBill,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleUpdateBill,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "0.3rem" }}>
        <Tooltip title="Edit">
          <IconButton size="small" onClick={() => table.setEditingRow(row)}>
            <SvgIcon component={EditAltIcon} fontSize={"small"} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            color="error"
            size="small"
            onClick={() => openDeleteConfirmModal(row)}
          >
            <SvgIcon component={TrashIcon} fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        // size="small"
        disableElevation
        sx={{
          alignItems: "center",
          whiteSpace: "nowrap",
          // px: 5,
          border: "1px solid black",
        }}
        color="mainButton"
        // disableElevation
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Add New
      </Button>
    ),
    renderToolbarInternalActions: ({ table }) => {
      return (
        <>
          <IconButton onClick={() => setOpenTopBar((prev) => !prev)}>
            <ChevronLeftIcon />
          </IconButton>
          <Slide direction="up" in={openTopBar} mountOnEnter unmountOnExit>
            {
              <Box>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
              </Box>
            }
          </Slide>
        </>
      );
    },
    positionToolbarAlertBanner: "top",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        background: theme.palette.table.header,
        color: theme.palette.text.tableHead,
        letterSpacing: "1px",
      }),
    },
    muiTableContainerProps: {
      sx: {
        // borderLeft: "2px solid #1AB519",
        // borderRight: "2px solid #1AB519",
        borderRadius: 2,
        maxWidth: "100vw",
      },
    },
    muiBottomToolbarProps: {
      sx: (theme) => ({
        // py: 2,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
      }),
    },
    muiTopToolbarProps: {
      sx: (theme) => ({
        // py: 2,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
      }),
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiSelectCheckboxProps: { color: "secondary" },
    muiTableBodyCellProps: {
      sx: (theme) => ({
        color: theme.palette.text.secondary,
        lineHeight: "175%",
        fontWeight: 400,
      }),
    },
    state: {
      isLoading: isLoadingBills,
      isSaving: isCreatingBill || isUpdatingBill || isDeletingBill,
      showAlertBanner: isLoadingError,
      showProgressBars: isFetchingBills,
    },
  });

  return <MaterialReactTable table={table} />;
};

const BTravelListProvider = (props) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <BTravelList props={props} />
  </LocalizationProvider>
);

export default BTravelListProvider;

const validateRequired = (value) => value !== "";
const validateDateRequired = (value) => value?.getTime() === value?.getTime();
// const validateEmail = (email) =>
//   !!email.length &&
//   email
//     .toLowerCase()
//     .match(
//       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//     );

function validateInputData(colField) {
  return {
    from: !validateDateRequired(colField.from) ? "Required Field" : "",
    to: !validateDateRequired(colField.to) ? "Required Field" : "",
    cost: !validateRequired(colField.cost) ? "Required Field" : "",
    sub_category: !validateRequired(colField.sub_category)
      ? "Required Field"
      : "",
    consumed_value: !validateRequired(colField.consumed_value)
      ? "Required Field"
      : "",
    location: !validateRequired(colField.location) ? "Required Field" : "",
  };
}

function getDepartments(data) {
  const departments = [];

  function extractDepartments(node) {
    if (node.department) {
      node.department.forEach((department) => {
        departments.push(department);
      });
    }
    if (node.facility) {
      node.facility.forEach((facility) => extractDepartments(facility));
    }
    if (node.section) {
      node.section.forEach((section) => extractDepartments(section));
    }
    if (node.floor) {
      node.floor.forEach((floor) => extractDepartments(floor));
    }
  }

  data.forEach((country) => extractDepartments(country));

  return departments;
}

BTravelListProvider.propTypes = {
  sub_category: PropTypes.string.isRequired,
  consumed_value: PropTypes.string.isRequired,
  columnVisibility: PropTypes.bool.isRequired,
  unit: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};
