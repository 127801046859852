import { Typography } from "@mui/material";
import InputField from "../FormField/InputField";

export default function Threshold(props) {
  const {
    formField: { ThresholdCost },
  } = props;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Device Threshold Cost
      </Typography>
      <InputField
        name={ThresholdCost.name}
        label={ThresholdCost.label}
        fullWidth
        type="number"
        sx={{ marginBottom: 1 }}
        autoFocus
      />
    </>
  );
}
