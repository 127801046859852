import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/GhgComp/@TabsGHG";
import ByScope from "../components/GhgComp/page/ByScope";
import ByDepartment from "../components/GhgComp/page/ByDepartment";
import ByYoY from "../components/GhgComp/page/ByYoY";

const GhgScopePage = () => {
  const ismobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const isDark = localStorage.getItem("choosenTheme") === "dark";
    isDark
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  return (
    <>
      <Box width={"100%"} height={"5em"}>
        {!ismobile && (
          <Box display="flex" justifyContent="center">
            <img
              src="/images/carbonlnk-logo.svg"
              width={"73px"}
              height={"40px"}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ width: "100%" }}>
        <Tabs defaultValue="tab1">
          <TabsList variant="solid" className="ml-12">
            <TabsTrigger value="tab1">Emission By Scope</TabsTrigger>
            <TabsTrigger value="tab2">Emission By Category</TabsTrigger>
            <TabsTrigger value="tab3">Year On Year</TabsTrigger>
          </TabsList>
          <div className="ml-2">
            <TabsContent
              value="tab1"
              className="space-y-2 text-sm leading-7 text-gray-600 dark:text-gray-500"
            >
              <ByScope />
            </TabsContent>
            <TabsContent
              value="tab2"
              className="space-y-2 text-sm leading-7 text-gray-600 dark:text-gray-500"
            >
              <ByDepartment />
            </TabsContent>
            <TabsContent
              value="tab3"
              className="space-y-2 text-sm leading-7 text-gray-600 dark:text-gray-500"
            >
              <ByYoY />
            </TabsContent>
          </div>
        </Tabs>
      </Box>
    </>
  );
};

export default GhgScopePage;
