import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import { Brightness4, Brightness7, BrightnessAuto } from "@mui/icons-material";
import { useState } from "react";
const choosenTheme = localStorage.getItem("choosenTheme");

//https://dribbble.com/shots/19356267/attachments/14498367?mode=media

const ChangeTheme = () => {
  const preferSystem = useMediaQuery("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";
  const [alignment, setAlignment] = useState(choosenTheme);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      localStorage.setItem("choosenTheme", newAlignment);
      window.location.reload();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        color: "text.primary",
        borderRadius: 1,
        p: 3,
      }}
    >
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton value="light" aria-label="left aligned">
          <Brightness7 color="inherit" />
        </ToggleButton>
        <ToggleButton value="dark" aria-label="centered">
          <Brightness4 color="inherit" />
        </ToggleButton>
        <ToggleButton value={preferSystem} aria-label="right aligned">
          <BrightnessAuto />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ChangeTheme;
