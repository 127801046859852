import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const topicSubscribe = createAsyncThunk(
  "topic/sub",
  async (
    { topic, costDay, costNight, peakTimeStart, peakTimeEnd, id },
    thunkAPI
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL_MQTT}/api/v1/subscribe`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            topic,
            costDay,
            costNight,
            peakTimeStart,
            peakTimeEnd,
            id,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      // const message =
      //     (error.response &&
      //         error.response.data &&
      //         error.response.data.message) ||
      //     error.message ||
      //     error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const topicUnSubscribe = createAsyncThunk(
  "topic/unsub",
  async ({ topic }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL_MQTT}/api/v1/unsubscribe`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            topic,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      // const message =
      //     (error.response &&
      //         error.response.data &&
      //         error.response.data.message) ||
      //     error.message ||
      //     error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);
