import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import * as L from "leaflet";
import "leaflet-defaulticon-compatibility";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function FacilityAddress({ renderedCellValue }) {
  const [open, setOpen] = useState(false);
  const [mapShow, setMapShow] = useState(false);
  const position = [renderedCellValue?.lat, renderedCellValue?.lng];
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        color="inherit"
        variant="text"
        size="small"
        onClick={handleClickOpen}
      >
        {renderedCellValue?.facility}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ backdropFilter: "blur(3px)" }}
        // maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Location Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box display="flex">
            <Typography gutterBottom display="flex" minWidth={100}>
              Country
            </Typography>
            <Typography color="initial">
              : {renderedCellValue?.country}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography gutterBottom display="flex" minWidth={100}>
              Facility
            </Typography>
            <Typography color="initial">
              : {renderedCellValue?.facility}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography gutterBottom display="flex" minWidth={100}>
              Section
            </Typography>
            <Typography color="initial">
              : {renderedCellValue?.section}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography gutterBottom display="flex" minWidth={100}>
              Floor
            </Typography>
            <Typography color="initial">
              : {renderedCellValue?.floor}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography gutterBottom display="flex" minWidth={100}>
              Department
            </Typography>
            <Typography color="initial">
              : {renderedCellValue?.department}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={3}
            alignContent="center"
            alignItems="flex-end"
          >
            <Typography
              inputProps={{
                step: 0.5,
              }}
              gutterBottom
            >
              lat: {renderedCellValue?.lat}
            </Typography>
            <Typography gutterBottom>lang: {renderedCellValue?.lng}</Typography>
            <Button
              // gutterBottom
              ml={"10%"}
              size="small"
              variant="text"
              disabled={!renderedCellValue?.lat}
              sx={{ color: "grey" }}
              onClick={() => setMapShow(!mapShow)}
            >
              {!mapShow ? "Want to See on Map?" : "Close"}
            </Button>
          </Box>
          {mapShow && (
            <MapContainer
              center={position}
              zoom={10}
              scrollWheelZoom={false}
              style={{ height: "100vh", width: "100wh" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={position}></Marker>
            </MapContainer>
          )}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
