import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import FacilityVertical from "../components/SiteDetails/vertical/FacilityVertical";
import SectionFloorVertical from "../components/SiteDetails/vertical/SectionFloorVertical";
import DepartmentVertical from "../components/SiteDetails/vertical/DepartmentVertical";
import { getSites } from "../features/sdSlice";
import CordinateSelctor from "../components/SiteDetails/vertical/CordinateSelctor";

const SiteDetails = () => {
  const ismobile = useMediaQuery("(max-width:600px)");
  const [sectionFloorData, setSectionFloorData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const dispatch = useDispatch();
  const { value, index, isLoading, isError, isSuccess } = useSelector(
    (state) => state.site_details
  );

  useEffect(() => {
    setDepartmentData([]);
    index.countryIndex !== null &&
      setSectionFloorData(
        value[index?.countryIndex]?.facility[index?.facilityIndex]?.section ??
          []
      );
  }, [index.countryIndex, index.facilityIndex, value]);

  useEffect(() => {
    if (sectionFloorData.length > 0) {
      setDepartmentData(
        value[index?.countryIndex]?.facility[index?.facilityIndex]?.section[
          index?.sectionIndex
        ]?.floor[index?.floorIndex]?.department ?? []
      );
    }
  }, [index.sectionIndex, index.floorIndex, index.departmentIndex, value]);

  useEffect(() => {
    dispatch(getSites());
  }, []);

  return (
    <>
      <Box width={"100%`"} height={"5em"}>
        {!ismobile && (
          <Box position={"absolute"} right={"45%"} left={"50%"}>
            <img
              src="images/carbonlnk-logo.svg"
              width={"144px"}
              height={"60px"}
            />
          </Box>
        )}
      </Box>
      {/* <button onClick={() => console.log(index)}>dzfkjds</button> */}
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
        })}
        sx={{ width: "100%" }}
      >
        <Box display="flex" mx={2}>
          <FacilityVertical data={value} />
          <SectionFloorVertical data={sectionFloorData} />
          <DepartmentVertical data={departmentData} />
          <Box
            component="main"
            sx={{ flexGrow: 1, width: { sm: "32%" } }}
            ml={3}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box height={"100vh"} width={"100vw"}>
                {departmentData.length > 0 && <CordinateSelctor forEdit={false}/>}
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default SiteDetails;
