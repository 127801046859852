import { Box, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  addCountry,
  addDepartment,
  addFacility,
  addFloor,
  addSection,
} from "../../../features/sdSlice";
import { useEffect, useState } from "react";
import CordinateSelctor from "../vertical/CordinateSelctor";

const validationSchema = yup.object({
  country: yup.string().required("Enter a valid field"),
  facility: yup.string().required("Enter a valid field"),
  section: yup.string().required("Enter a valid field"),
  floor: yup.number().required("Enter a valid field"),
  department: yup.string().required("Enter a valid field"),
});

const AddEntrySD = ({ onClose, method, isEdit }) => {
  const dispatch = useDispatch();
  const { value, index } = useSelector((state) => state.site_details);
  const [latLang, setLatLang] = useState({ lat: 51.507, lng: -0.08 });
  const [initialData, setinitialData] = useState({
    country: "",
    facility: "",
    section: "",
    floor: "",
    department: "",
    // lat: "",
    // lng: "",
  });

  useEffect(() => {
    const data = value[index?.countryIndex];
    switch (isEdit) {
      case false:
        switch (method) {
          // case "country":
          case "facility":
            setinitialData({
              country: data?.key,
            });
            break;
          case "section":
            setinitialData({
              country: data?.key,
              facility: data?.facility[index?.facilityIndex]?.key,
            });
            break;
          case "floor":
            setinitialData({
              country: data?.key,
              facility: data?.facility[index?.facilityIndex]?.key,
              section:
                data?.facility[index?.facilityIndex]?.section[
                  index?.sectionIndex
                ]?.key,
            });
            break;
          case "department":
            setinitialData({
              country: data?.key,
              facility: data?.facility[index?.facilityIndex]?.key,
              section:
                data?.facility[index?.facilityIndex]?.section[
                  index?.sectionIndex
                ]?.key,
              floor:
                data?.facility[index?.facilityIndex]?.section[
                  index?.sectionIndex
                ]?.floor[index?.floorIndex]?.key,
            });
            break;
        }
        break;
      case true:
        switch (method) {
          case "country":
            setinitialData({
              country: data?.key,
            });
          case "facility":
            setinitialData({
              country: data?.key,
              facility: data?.facility[index?.facilityIndex]?.key,
            });
            break;
          case "section":
            setinitialData({
              country: data?.key,
              facility: data?.facility[index?.facilityIndex]?.key,
              section:
                data?.facility[index?.facilityIndex]?.section[
                  index?.sectionIndex
                ]?.key,
            });
            break;
          case "floor":
            setinitialData({
              country: data?.key,
              facility: data?.facility[index?.facilityIndex]?.key,
              section:
                data?.facility[index?.facilityIndex]?.section[
                  index?.sectionIndex
                ]?.key,
              floor:
                data?.facility[index?.facilityIndex]?.section[
                  index?.sectionIndex
                ]?.floor[index?.floorIndex]?.key,
            });
            break;
          case "department":
            setinitialData({
              country: data?.key,
              facility: data?.facility[index?.facilityIndex]?.key,
              section:
                data?.facility[index?.facilityIndex]?.section[
                  index?.sectionIndex
                ]?.key,
              floor:
                data?.facility[index?.facilityIndex]?.section[
                  index?.sectionIndex
                ]?.floor[index?.floorIndex]?.key,
              department:
                data?.facility[index?.facilityIndex]?.section[
                  index?.sectionIndex
                ]?.floor[index?.floorIndex]?.department[index?.departmentIndex]
                  ?.key,
            });
            break;
        }
        break;
    }
    return () => {
      setinitialData([]);
    };
  }, [index]);

  const lowerize = (obj) =>
    Object.keys(obj).reduce((acc, k, i) => {
      // acc[k] = obj[k].toLowerCase();
      acc[k] = i !== 3 ? obj[k].toLowerCase() : obj[k];
      return acc;
    }, {});

  const getCordinates = (props) =>
    setLatLang({ lat: props.lat, lng: props.lng });

  const formik = useFormik({
    initialValues: {
      country: initialData?.country ?? "",
      facility: initialData?.facility ?? "",
      section: initialData?.section ?? "",
      floor: initialData?.floor ?? "",
      department: initialData?.department ?? "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      const lowerValues = lowerize(values);
      switch (method) {
        case "country":
          {
            const isAlreadyPresent = false;
            value.map((e) => {
              if (e.key === lowerValues.country) {
                setErrors({ country: "Duplicate Value" });
                isAlreadyPresent = true;
              }
            });

            !isAlreadyPresent &&
              dispatch(addCountry(lowerValues)).then(onClose());
          }
          break;
        case "facility":
          {
            const isAlreadyPresent = false;
            value[index.countryIndex].facility.map((e) => {
              if (e.key === lowerValues.facility) {
                setErrors({ facility: "Duplicate Value" });
                isAlreadyPresent = true;
              }
            });

            !isAlreadyPresent &&
              dispatch(addFacility(lowerValues)).then(onClose());
          }
          break;
        case "section":
          {
            const isAlreadyPresent = false;
            value[index.countryIndex].facility[index.facilityIndex].section.map(
              (e) => {
                if (e.key === lowerValues.section) {
                  setErrors({ section: "Duplicate Value" });
                  isAlreadyPresent = true;
                }
              }
            );

            !isAlreadyPresent &&
              dispatch(addSection(lowerValues)).then(onClose());
          }
          break;
        case "floor":
          {
            const isAlreadyPresent = false;
            value[index.countryIndex].facility[index.facilityIndex].section[
              index.sectionIndex
            ].floor.map((e) => {
              if (Number(e.key) === Number(lowerValues.floor)) {
                setErrors({ floor: "Duplicate Value" });
                isAlreadyPresent = true;
              }
            });

            !isAlreadyPresent &&
              dispatch(addFloor(lowerValues)).then(onClose());
          }
          break;
        case "department":
          {
            const isAlreadyPresent = false;
            value[index.countryIndex].facility[index.facilityIndex].section[
              index.sectionIndex
            ].floor[index.floorIndex].department.map((e) => {
              if (e.key === lowerValues.department) {
                setErrors({ department: "Duplicate Value" });
                isAlreadyPresent = true;
              }
            });

            !isAlreadyPresent &&
              dispatch(addDepartment({ ...lowerValues, ...latLang })).then(
                onClose()
              );
          }
          break;
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between" gap={2} mb={2}>
          <TextField
            id="country"
            name="country"
            label="country"
            value={formik.values.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
            disabled={!isEdit && initialData?.country}
          />
          <TextField
            id="facility"
            name="facility"
            label="facility"
            value={formik.values.facility}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.facility && Boolean(formik.errors.facility)}
            helperText={formik.touched.facility && formik.errors.facility}
            disabled={!isEdit && initialData?.facility}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={2} mb={2}>
          <TextField
            fullWidth
            id="section"
            name="section"
            label="section"
            value={formik.values.section}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.section && Boolean(formik.errors.section)}
            helperText={formik.touched.section && formik.errors.section}
            disabled={!isEdit && initialData?.section}
          />
          <TextField
            fullWidth
            id="floor"
            name="floor"
            label="floor"
            value={formik.values.floor}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.floor && Boolean(formik.errors.floor)}
            helperText={formik.touched.floor && formik.errors.floor}
            disabled={
              (!isEdit && initialData?.floor) ||
              (!isEdit && initialData?.floor === 0)
            }
          />
        </Box>
        <Box gap={2} mb={2}>
          <TextField
            fullWidth
            id="department"
            name="department"
            label="department"
            value={formik.values.department}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.department && Boolean(formik.errors.department)
            }
            helperText={formik.touched.department && formik.errors.department}
          />
          {(initialData?.floor ||
            initialData?.floor === 0 ||
            formik.values.department) && (
            <CordinateSelctor
              forEdit={true}
              getCordinates={getCordinates}
              // lat={initialData?.lat}
              // lng={initialData?.lng}
            />
          )}
        </Box>
        <Button variant="contained" fullWidth type="submit">
          {isEdit ? "Edit Record" : "Add Record"}
        </Button>
      </form>
    </>
  );
};

export default AddEntrySD;
