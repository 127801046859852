import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import { Box, DialogContentText, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { removeDeviceLimit } from "../../features/authSlice";
import SnackBarFunction from "../SnackBarFunction";

export const DeviceLimit = (props) => {
  const { onClose, value, open, email, ...other } = props;
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  });

  const dispatch = useDispatch();

  const handleRemove = ({ email, ipofDeviceToRemove }) => {
    dispatch(
      removeDeviceLimit({ email: email?.toLowerCase(), ipofDeviceToRemove })
    ).then((onResolved) => {
      if (onResolved.type === "auth/remove/rejected") {
        setErros({
          ...errors,
          loading: true,
        });
        setTimeout(() => {
          setErros({
            ...errors,
            loading: false,
          });
        }, 3000);
      } else {
        onClose();
      }
    });
  };

  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
        {...other}
      >
        <DialogTitle id="alert-dialog-title">
          {"Remove Device Access for CarbonLnk?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`You reach limit of maximum number of device Login   Would you like to remove this device acess ?`}
          </DialogContentText>
          <DialogContent>
            {value.map((e, index) => (
              <Box display={"flex"} key={index}>
                <DialogContentText id="inside-dialog" paddingRight={1}>
                  Device IP {e.deviceIP}
                </DialogContentText>
                Last Login: {e.lastLogin}
                <Button
                  key={index}
                  variant="contained"
                  color="error"
                  sx={{ marginBottom: 2 }}
                  onClick={() => {
                    handleRemove({
                      email: email,
                      ipofDeviceToRemove: e.deviceIP,
                    });
                  }}
                >
                  Logout
                </Button>
              </Box>
            ))}
          </DialogContent>
        </DialogContent>
        {/* <DialogActions>
          <Button color="ternary" onClick={handleCancel}>
            Disagree
          </Button>
          <Button color="ternary" onClick={handleOk} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
    </>
  );
};
