import * as Yup from "yup";
// import moment from 'moment';
import checkoutFormModel from "./checkoutFormModel";
const {
  formField: {
    DeviceGateway,
    DeviceSerialNumber,
    DeviceModel,
    DeviceChannel,
    DeviceNickName,
    Location,
  },
} = checkoutFormModel;

export default [
  Yup.object().shape({
    [DeviceModel.name]: Yup.string().required(
      `${DeviceModel.requiredErrorMsg}`
    ),
    [DeviceSerialNumber.name]: Yup.number().when("DeviceModel", {
      is: (DeviceModel) => DeviceModel && DeviceModel.substring(0, 2) === "ss",
      then: (schema) => schema,
      otherwise: (schema) => schema.required(),
    }),
    // [DeviceSerialNumber.name]: Yup.number().required(
    //   `${DeviceSerialNumber.requiredErrorMsg}`
    // ),
    [DeviceGateway.name]: Yup.number().when("DeviceModel", {
      is: (DeviceModel) => DeviceModel && DeviceModel.substring(0, 2) === "ss",
      then: (schema) => schema,
      otherwise: (schema) => schema.required(),
    }),
    [DeviceChannel.name]: Yup.number().when("DeviceModel", {
      is: (DeviceModel) => DeviceModel && DeviceModel.substring(0, 2) === "ss",
      then: (schema) => schema,
      otherwise: (schema) => schema.required(),
    }),
  }),
  Yup.object().shape({
    [DeviceNickName.name]: Yup.string().required(
      `${DeviceNickName.requiredErrorMsg}`
    ),
  }),
  Yup.object().shape({
    [Location.name]: Yup.string().required(`${Location.requiredErrorMsg}`),
  }),
  // Yup.object().shape({
  //   [Lat.name]: Yup.string().required(`${Lat.requiredErrorMsg}`),
  //   [Lang.name]: Yup.string().required(`${Lang.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [Facility.name]: Yup.string().required(`${Facility.requiredErrorMsg}`),
  //   [Section.name]: Yup.string().required(`${Section.requiredErrorMsg}`),
  //   [Floor.name]: Yup.string().required(`${Floor.requiredErrorMsg}`),
  // }),
  // Yup.object().shape({
  //   [Department.name]: Yup.string().required(`${Department.requiredErrorMsg}`),
  // }),
];
