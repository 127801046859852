import {
  Text,
  Flex,
  Bold,
  BarList,
  Col,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableHeaderCell,
} from "@tremor/react";

const TGSubCategory = ({ filteredData }) => {
  return (
    <>
      <Col numColSpan={1} numColSpanLg={2}>
        <Flex className="py-4 mt-5">
          <Text>
            <Bold>Source</Bold>
          </Text>
        </Flex>

        <BarList
          data={filteredData}
          // showAnimation={true}
          // animationDuration={50000}
          className="mt-4"
          color="amber"
        />
      </Col>
      <Table className="mt-10 ml-10">
        <TableHead>
          <TableRow>
            <TableHeaderCell className="w-28 p-0 h-8">{}</TableHeaderCell>
            <TableHeaderCell className="w-28 p-0">{}</TableHeaderCell>
            <TableHeaderCell className="w-28 p-0">{}</TableHeaderCell>
            {/* <div className="flex  justify-between">
              <span className={"w-1 shrink-0 rounded"} aria-hidden={true} />
              <IconButton
                aria-label="hide"
                size="small"
                onClick={() => getId("")}
                // className="w-28 p-0"
              >
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            </div> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((item, index) => (
            <TableRow className="border-none" key={index}>
              <TableCell className="p-0 pt-6">{item.CO2}</TableCell>
              <TableCell className="p-0 pt-6">
                <Text>{item.CH4}</Text>
              </TableCell>
              <TableCell className="p-0 pt-6">
                <Text>{item?.N2O ?? ""}</Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default TGSubCategory;
