import clsx from "clsx";
import { twMerge } from "tailwind-merge";

const sumByKey = (arr, key, value) => {
  // const grouped = Map.groupBy(arr, (o) => o[key]);
  // return Array.from(grouped.values(), (group) =>
  //   group.reduce((acc, obj) => {
  //     // console.log(acc[value], value, acc);
  //     return {
  //       ...obj,
  //       CO2e: (acc[value]?.CO2e ?? 0) + obj[value].CO2e,
  //       CO2: (acc[value]?.CO2 ?? 0) + obj[value].CO2,
  //       N2O: (acc[value]?.N2O ?? 0) + obj[value].N2O,
  //       CH4: (acc[value]?.CH4 ?? 0) + obj[value].CH4,
  //     };
  //   }, {})
  // );
  return arr;
};
const focusRing = [
  // base
  "outline outline-offset-2 outline-0 focus-visible:outline-2",
  // outline color
  "outline-blue-500 dark:outline-blue-500",
];

function cx(...args) {
  return twMerge(clsx(...args));
}
export { sumByKey, focusRing, cx };
