import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// import SDFilter from "../SDFilter";
import ViewCompactRoundedIcon from "@mui/icons-material/ViewCompactRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import StyledMenuComp from "../MenuCompSD";
import { useDispatch } from "react-redux";
import { changeIndex } from "../../../features/sdSlice";

const DepartmentVertical = ({ data }) => {
  const [openedIndex, setOpenedIndex] = useState();
  const [anchorEl, setAnchorEl] = useState({
    isOpen: null,
    isSubItem: null,
    isMain: null,
  });
  const open = Boolean(anchorEl.isOpen);
  const dispatch = useDispatch();

  const handleClickOpenedIndex = (index) => {
    openedIndex === index ? setOpenedIndex(undefined) : setOpenedIndex(index);
    openedIndex !== index &&
      dispatch(
        changeIndex({
          departmentIndex: parseInt(index),
        })
      );
  };
  const handleClickMenu = ({ event, dispatchData, isMain, isSubItem }) => {
    setAnchorEl({ isOpen: event.currentTarget, isMain, isSubItem });
    dispatch(changeIndex(dispatchData));
  };

  const handleClose = () => {
    setAnchorEl({ ...anchorEl, isOpen: null });
  };

  useEffect(() => {
    return () => {
      setOpenedIndex(null);
    };
  }, [data]);

  function checkWhitespace(str) {
    return /\s/.test(str);
  }

  return (
    <>
      <Box
        width="21%"
        mb={1}
        minHeight="80vh"
        borderRight="2px solid"
        borderColor="#eeeeee"
        pr={1}
        px={2}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mt={1}
          alignItems="center"
        >
          <Typography variant="body1" color="initial" lineHeight={2}>
            Department
          </Typography>
          <IconButton
            size="small"
            sx={{ p: 0, m: 0, mr: 2 }}
            aria-label="more"
            onClick={(event) => {
              event.stopPropagation();
              handleClickMenu({
                event,
                isSubItem: false,
                isMain: true,
              });
            }}
          >
            <MoreHorizRoundedIcon color="disabled" />
          </IconButton>
        </Box>
        {/* <SDFilter /> */}
        <List component="div" disablePadding>
          {data.map((value, index) => (
            <ListItemButton
              sx={{
                pl: 4,
                bgcolor: openedIndex === index && "#eeeeee9c",
              }}
              onClick={() => handleClickOpenedIndex(index)}
            >
              <ListItemIcon>
                <ViewCompactRoundedIcon />
              </ListItemIcon>
              <ListItemText
                primary={value.key}
                primaryTypographyProps={{
                  fontWeight: "light",
                  letterSpacing: "0.15rem",
                  textTransform: checkWhitespace(value?.key)
                    ? "capitalize"
                    : "uppercase",
                }}
              />
              <IconButton
                size="small"
                sx={{ p: 0, m: 0 }}
                aria-label="more"
                onClick={(event) =>
                  handleClickMenu({
                    event,
                    dispatchData: {
                      departmentIndex: parseInt(index),
                    },
                    isSubItem: true,
                  })
                }
              >
                <MoreHorizRoundedIcon color="disabled" />
              </IconButton>
            </ListItemButton>
          ))}
        </List>
      </Box>
      <StyledMenuComp
        anchorEl={anchorEl.isOpen}
        isMain={anchorEl.isMain}
        isSubItem={anchorEl.isSubItem}
        open={open}
        handleClose={handleClose}
        subHeader1={"Department"}
        subHeader2={"Department"}
      />
    </>
  );
};

export default DepartmentVertical;
