import { Box, Button, Typography } from "@mui/material";
import React from "react";

const HelpBar = () => {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        aria-label="guide"
        bgcolor={"background.default"}
        p={2}
        borderRadius={1}
      >
        <div>
          <Typography lineHeight={1.5} letterSpacing={"0.3em"}>
            Need Help?
          </Typography>
          <Typography
            variant="subtitle2"
            fontSize={"0.8rem"}
            letterSpacing={"0.2em"}
          >
            Here is guide for help
          </Typography>
        </div>
        <div>
          <Button variant="outlined" color="button" size="small" sx={{ mr: 3 }}>
            Download Guide
          </Button>
          <Button variant="text" color="iconColor" size="small">
            Hide
          </Button>
        </div>
      </Box>
    </>
  );
};

export default HelpBar;
