import { Alert, Snackbar } from "@mui/material";
import React from "react";

const SnackBarFunction = ({ isLoadingButton, severity, message }) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isLoadingButton}
      >
        <Alert
          severity={severity}
          sx={{ width: "100%", border: "2px solid black", zIndex: 3 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackBarFunction;
