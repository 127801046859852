import { useFormikContext } from "formik";
import {
  Typography,
  Divider,
  ListItemText,
  ListItem,
  Box,
} from "@mui/material";
import { Fragment } from "react";

export default function Review() {
  const { values: formValues } = useFormikContext();
  return (
    <>
      <Typography
        paddingX={10}
        variant="h6"
        gutterBottom
        color={"text.primary"}
      >
        Review Summary
      </Typography>
      <Box
        sx={{
          width: "100%",
          px: 10,
          bgcolor: "background.paper",
          color: "text.primary",
        }}
      >
        <Divider />
        <nav aria-label="secondary folders">
          {Object.keys(formValues).map((key, index) => {
            return (
              <Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemText primary={`${key ?? ""}:`} />
                  <ListItemText
                    primary={formValues[key] ?? ""}
                    sx={{
                      alignItems: "left",
                      alignContent: "left",
                      justifyContent: "left",
                      marginLeft: 15,
                    }}
                  />
                </ListItem>
              </Fragment>
            );
          })}
        </nav>
      </Box>
    </>
  );
}
