import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const SideMenu = ({
  title,
  footprint,
  sideMenuItemClick,
  selectedMenuItem,
}) => {
  return (
    <>
      <Box
        pl={1.6}
        py={1}
        bgcolor={
          selectedMenuItem === title ? "background.paper" : "background.default"
        }
        boxShadow={selectedMenuItem === title ? 1 : 0}
        border={"1px solid rgba(0, 0, 0, 0.12)"}
        borderRadius={0.8}
        mb={1}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "background.paper",
            boxShadow: 1,
          },
        }}
        onClick={(event) => {
          sideMenuItemClick(event, title);
        }}
      >
        <div>
          <Typography variant="subtitle1" fontWeight={600}>
            {title}
          </Typography>
          <Typography variant="caption">
            Carbon Footprint -{footprint} tCO2
          </Typography>
        </div>

        <ArrowForwardIosOutlined
          sx={{ color: "#6e6a6a", mr: 1 }}
          fontSize="small"
        />
      </Box>
    </>
  );
};

export default SideMenu;
