import { Box, Typography } from "@mui/material";
import HelpBar from "./HelpBar";
import BTravelList from "./BTravelList";
import { travelList } from "./sub_cat_list";

const RGas = () => {
  return (
    <>
      <HelpBar />
      <Box
        bgcolor={"background.default"}
        p={2}
        borderRadius={1}
        mt={4}
        minHeight={"100vh"}
      >
        <Box display={"flex"} flexDirection={"row-reverse"} mb={3}>
          <Typography variant="subtitle2" color="icon">
            Updated on 09 Feb 2024
          </Typography>
        </Box>
        <Typography mb={2}>
          Do you have a fleet of vehicles (owned or leased)
        </Typography>
        {/* <Typography mb={3}>
      This dosen't include employee commute or company owned or lealesd
      vehicle
    </Typography> */}
        <BTravelList
          category={"Refrigerant Gases"}
          sub_category={"Refrigerant Type"}
          sub_category_list={travelList ?? []}
          consumed_value={"Gas Consumed"}
          columnVisibility={false}
          unit={""}
        />
      </Box>
    </>
  );
};

export default RGas;
