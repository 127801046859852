import { Typography } from "@mui/material";
import InputField from "../FormField/InputField";

export default function LekageChannel(props) {
  const {
    formField: { LekageChannel },
  } = props;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Device Lekage Channel
      </Typography>
      <InputField
        name={LekageChannel.name}
        label={LekageChannel.label}
        fullWidth
        type="number"
        sx={{ marginBottom: 1 }}
        autoFocus
      />
    </>
  );
}
