import { useState } from "react";
import TabGraph from "../TabGraph";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ByScope = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Scope 1" />
          <Tab label="Scope 2" />
          <Tab label="Scope 3" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <TabGraph scopeType={"scope1_co2"} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TabGraph scopeType={"scope2_co2"} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <TabGraph scopeType={"scope3_co2"} />
      </CustomTabPanel>
    </>
  );
};

export default ByScope;
