import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { TimePicker } from "react-ios-time-picker";
import AddIcon from "@mui/icons-material/Add";
import SnackBarFunction from "../../../../SnackBarFunction";

const TimeRange = ({ getData }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mt: 2,
          gap: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2">{` Off Time Start  `}</Typography>
        <TimePicker
          onChange={(timeValue) => {
            setStartDate(timeValue);
          }}
          value={startDate}
        />

        <Typography variant="subtitle2">{` Off Time End  `}</Typography>
        <TimePicker
          onChange={(timeValue) => {
            setEndDate(timeValue);
          }}
          value={endDate}
        />
        <IconButton
          color="ternary"
          sx={{ backgroundColor: "icon" }}
          onClick={() => {
            if (startDate !== undefined && endDate !== undefined) {
              getData({ startDate, endDate });
            } else {
              setErros({
                loading: true,
                severity: "error",
                message: "Date is Empty",
              });
              setTimeout(() => {
                setErros({
                  ...errors,
                  loading: false,
                });
              }, 1500);
            }
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <SnackBarFunction
        isLoadingButton={errors.loading}
        severity={errors.severity}
        message={errors.message}
      />
    </>
  );
};

export default TimeRange;
