import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// const user = localStorage.getItem("user");
import { encryptData, decryptData } from "../components/utils/encrypt-decrypt";
export const userlogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/login/user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        // localStorage.setItem("user", JSON.stringify(data));
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verify",
  async ({ email, otp, deviceIP, forCP, newPassword }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/login/verify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email,
            otp,
            deviceIP,
            forCP,
            newPassword,
          }),
        }
      );
      const data = await res.json();
      // console.log(data);

      if (res.status === 201) {
        localStorage.setItem(
          "ebgbvbfbjrbgjkkjrjbg",
          encryptData(JSON.stringify(data?.user?.email))
        );
        data?.user?.logo !== undefined &&
          localStorage.setItem(
            "lsdvfsdfksmdvjkb",
            encryptData(JSON.stringify(data.user.logo))
          );
        data?.user?.dashboardUrl !== undefined &&
          localStorage.setItem(
            "ddsmhfywief87we8f8",
            encryptData(JSON.stringify(data?.user?.dashboardUrl))
          );
        // const cvalue = data.user.dashboardUrl;
        // document.cookie = "url" + "=" + cvalue + ";" + ";path=/";
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resendOtp = createAsyncThunk(
  "auth/resend",
  async ({ email }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/login/resend`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const removeDeviceLimit = createAsyncThunk(
  "auth/removeLimiy",
  async ({ email, ipofDeviceToRemove }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/login/remove`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email,
            ipofDeviceToRemove,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     error.response.data.message) ||
      //   error.message ||
      //   error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const checkLogin = createAsyncThunk(
  "auth/check",
  async ({ deviceIP }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/check/${deviceIP}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (res.status === 200) {
        return "success";
      } else if (res.status === 404) {
        return thunkAPI.rejectWithValue("unauthorized");
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async ({ ipofDeviceToRemove }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/logout`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            ipofDeviceToRemove,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     error.response.data.message) ||
      //   error.message ||
      //   error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/password",
  async ({ oldPassword }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/change/password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            oldPassword,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    // user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.user = action.payload.user;
        // console.log("done");
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        // state.user = null;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
