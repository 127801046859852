import { DonutChart } from "@tremor/react";

const TGFacility = ({ data }) => {
  const valueFormatter = (number) =>
    `${new Intl.NumberFormat("en-IN").format(number).toString()}kg`;

  const allDataFacility = data.map((e) => ({
    name: e?.facility ?? "",
    value: e["CO2e"],
    CO2: e["CO2"],
    N2O: e["N2O"],
    CH4: e["CH4"],
  }));

  return (
    <div className="flex justify-center content-center">
      <DonutChart
        data={allDataFacility}
        category="value"
        index="name"
        className="w-52"
        valueFormatter={valueFormatter}
        animationDuration={true}
        // onValueChange={(e) => console.log(e)}
      />
    </div>
  );
};

export default TGFacility;
