const travelList = [
  "Bus",
  "Car Diesel",
  "Car Petrol",
  "Car CNG",
  "Car EV",
  "Car Hybrid",
  "Domestic flight",
  "International flight",
  "Sea",
  "Train",
  "Taxi",
];

const vehicleList = [
  "Car Diesel",
  "Car Petrol",
  "Car CNG",
  "Car EV",
  "Car Hybrid",
  "HGV 0% Laden",
  "HGV 50% Laden",
  "HGV 100% Laden",
  "HGV Avg. Laden",
  "HGV Refrigerated 0% Laden",
  "HGV Refrigerated 50% Laden",
  "HGV Refrigerated 100% Laden",
  "HGV Refrigerated Avg. Laden",
  "Motorbike",
  "Van Diesel",
  "Van Petrol",
  "Van CNG",
  "Van EV",
  "Van Hybrid",
];

const electricityList = [
  "Generated non-renewable",
  "Generated renewable",
  "Purchased non-renewable",
  "Purchased renewable",
];

const wasteList = [
  "Construction",
  "EEE",
  "Metal",
  "Mineral oil",
  "Organic Waste",
  "Plastic",
  "Paper",
  "Tyres",
  "Wood",
];

const waterList = ["Supply", "Treatment"];
export { travelList, vehicleList, electricityList, wasteList, waterList };
