import { Box, Typography } from "@mui/material";
import HelpBar from "./HelpBar";
import BTravelList from "./BTravelList";
import { wasteList } from "./sub_cat_list";

const Waste = () => {
  return (
    <>
      <HelpBar />
      <Box
        bgcolor={"background.default"}
        p={2}
        borderRadius={1}
        mt={4}
        minHeight={"100vh"}
      >
        <Box display={"flex"} flexDirection={"row-reverse"} mb={3}>
          <Typography variant="subtitle2" color="icon">
            Updated on 09 Feb 2024
          </Typography>
        </Box>
        <Typography mb={2}>
          Do you use third party suppliers for waste management
        </Typography>
        {/* <Typography mb={3}>
          This dosen't include employee commute or company owned or lealesd
          vehicle
        </Typography> */}

        <BTravelList
          category={"Waste"}
          sub_category={"Waste Type"}
          sub_category_list={wasteList ?? []}
          consumed_value={"Weight"}
          columnVisibility={false}
          unit={"TON"}
        />
      </Box>
    </>
  );
};

export default Waste;
