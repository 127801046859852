import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const notificationGet = createAsyncThunk(
  "noti/get",
  async ({}, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/notification`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        return data.message;
      } else if (res.status === 404) {
        return thunkAPI.rejectWithValue("unauthorized");
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     error.response.data.message) ||
      //   error.message ||
      //   error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);
