import { useEffect, useMemo, useState } from "react";
import {
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MaterialReactTable,
} from "material-react-table";
import { Box, IconButton, Slide } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { FacilityAddress } from "../Bills/MenuComponents/FacilityAddress";
const TemperatureDeviceList = ({ rows, getId }) => {
  const [data, setData] = useState(rows);
  const [rowSelection, setRowSelection] = useState({});
  const [openTopBar, setOpenTopBar] = useState(false);

  useEffect(() => {
    if (rows !== undefined) {
      setData(rows);
    }
  }, [rows]);

  useEffect(() => {
    const ids = Object.keys(rowSelection);
    getId(ids);
  }, [rowSelection]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "deviceNickName",
        // accessorFn: (row) => `${row.deviceNickName}`, //accessorFn used to join multiple data into a single cell
        id: "name", //id is still required when using accessorFn instead of accessorKey
        header: "Device Name",
        size: 180,
      },
      {
        accessorKey: "type",
        header: `Electriticty 
          Type`,
        size: 200,
      },
      {
        accessorKey: "location",
        header: "Location",
        size: 50,
        Cell: ({ renderedCellValue }) => (
          <>
            <FacilityAddress
              renderedCellValue={renderedCellValue?.facility}
              data={renderedCellValue}
            />
          </>
        ),
      },
      {
        accessorKey: "deviceModel",
        id: "deviceModel", //id is still required when using accessorFn instead of accessorKey
        header: "Device ID",
        size: 30,
      },
      {
        accessorKey: "deviceGateway",
        id: "deviceGateway", //id is still required when using accessorFn instead of accessorKey
        header: "Gateway ID",
        size: 50,
      },
      {
        accessorKey: "deviceSerialNumber", //accessorFn used to join multiple data into a single cell
        id: "deviceSerialNumber", //id is still required when using accessorFn instead of accessorKey
        header: "Device Serial",
        size: 50,
      },
      {
        accessorKey: "deviceChannel",
        id: "deviceChannel",
        header: `Transformer 
        Number`,
        size: 240,
      },
    ],
    []
  );
  return (
    <Box sx={{ width: "100%", px: 5, maxWidth: "100vw" }}>
      <Box sx={{ width: "100%", mb: 2 }}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnFilterModes
          enableColumnOrdering
          enableGrouping
          enablePinning
          // enableRowActions
          enableRowSelection
          // enableTopToolbar={false} //hide top toolbar
          // enableBottomToolbar={false} //hi
          enableColumnDragging={false}
          // positionGlobalFilter="left"
          getRowId={(row) => row._id} //give each row a more useful id
          onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
          state={{ rowSelection }}
          // initialState={{ grouping: ["department"], expanded: true }}
          positionToolbarAlertBanner="top"
          renderToolbarInternalActions={({ table }) => {
            return (
              <>
                <IconButton onClick={() => setOpenTopBar((prev) => !prev)}>
                  <ChevronLeftIcon />
                </IconButton>
                <Slide
                  direction="up"
                  in={openTopBar}
                  mountOnEnter
                  unmountOnExit
                >
                  {
                    <Box>
                      <MRT_ToggleGlobalFilterButton table={table} />
                      <MRT_ToggleFiltersButton table={table} />
                      <MRT_ShowHideColumnsButton table={table} />
                      <MRT_ToggleDensePaddingButton table={table} />
                      <MRT_ToggleFullScreenButton table={table} />
                    </Box>
                  }
                </Slide>
              </>
            );
          }}
          muiTableHeadCellProps={{
            sx: (theme) => ({
              // py: 2,
              background: theme.palette.table.header,
              color: theme.palette.text.tableHead,
              // lineHeight: "150%",
              letterSpacing: "1px",
            }),
          }}
          muiBottomToolbarProps={{
            sx: (theme) => ({
              // py: 2,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
            }),
          }}
          muiTopToolbarProps={{
            sx: (theme) => ({
              // py: 2,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
            }),
          }}
          muiTableContainerProps={{
            sx: {
              borderLeft: "2px solid theme.palette.table.header",
              borderRight: "2px solid theme.palette.table.header",
              borderRadius: 2,
              maxWidth: "100vw",
            },
          }}
          muiTableProps={{
            sx: () => ({
              minWidth: 750,
            }),
            size: "medium",
          }}
          muiSelectCheckboxProps={{ color: "secondary" }}
          muiTablePaperProps={{
            elevation: 0,
          }}
          muiTableBodyCellProps={{
            sx: (theme) => ({
              color: theme.palette.text.secondary,
              lineHeight: "175%",
              fontWeight: 400,
            }),
          }}
        />
      </Box>
    </Box>
  );
};

export default TemperatureDeviceList;
