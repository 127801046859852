import { Box, Typography } from "@mui/material";
import HelpBar from "./HelpBar";
import BTravelList from "./BTravelList";
import { travelList } from "./sub_cat_list";

const BTravel = () => {
  return (
    <>
      <HelpBar />
      <Box
        bgcolor={"background.default"}
        p={2}
        borderRadius={1}
        mt={4}
        minHeight={"100vh"}
      >
        <Box display={"flex"} flexDirection={"row-reverse"} mb={3}>
          <Typography variant="subtitle2" color="icon">
            Updated on 09 Feb 2024
          </Typography>
        </Box>
        <Typography mb={2}>Travel related for work</Typography>
        <Typography mb={3}>
          This dosen't include employee commute or company owned or leased
          vehicle
        </Typography>
        {/* <ul className="list-disc ml-5">
          <li>
            Now this is a story all about how, my life got flipped-turned upside
            down
          </li>
          <li>
            Now this is a story all about how, my life got flipped-turned upside
            down
          </li>
          <li>
            Now this is a story all about how, my life got flipped-turned upside
            down
          </li>
        </ul> */}

        <BTravelList
          category={"Busines Travel"}
          sub_category={"Mode of transport"}
          sub_category_list={travelList ?? []}
          consumed_value={"Distance Travelled"}
          columnVisibility={false}
          unit={"KM"}
        />
      </Box>
    </>
  );
};

export default BTravel;
