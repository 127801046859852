import { useEffect, useRef, useState } from "react";
import { Card, Select, SelectItem, Divider, Legend, Grid } from "@tremor/react";
import TGCategory from "./TG-Category";
import TGSubCategory from "./TG-SubCategory";
import TGFacility from "./TG-Facility";
import { useGHGScope } from "../../features/billSlice";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";

const TabGraph = ({ scopeType }) => {
  const refSC = useRef(null);
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [dateValueFrom, setDateValueFrom] = useState(dayjs("2020-01-02"));
  const [dateValueTo, setDateValueTo] = useState(dayjs("2027-06-09"));

  const {
    data: fetchedData = [],
    isError: isLoadingError,
    isFetching: isFetchingBills,
    isLoading: isLoadingBills,
    refetch,
  } = useGHGScope({
    from: dateValueFrom !== "" ? dateValueFrom : dayjs("2020-01-02"),
    to: dateValueTo !== "" ? dateValueTo : dayjs("2027-06-09"),
    scope: scopeType,
    facility: selectedFacility.length === 0 ? undefined : [selectedFacility],
  });

  const { value, index, isLoading, isError, isSuccess } = useSelector(
    (state) => state.site_details
  );

  function getFacility(data) {
    const facilitys = [];

    function extractFacilitys(node) {
      if (node.facility) {
        node.facility.forEach((facility) => {
          facilitys.push(facility);
        });
      }
    }

    data.forEach((country) => extractFacilitys(country));

    return facilitys;
  }

  useEffect(() => {
    setFilteredData(allDataCategory);
  }, [fetchedData]);

  useEffect(() => {
    refetch();
  }, [dateValueFrom, dateValueTo, selectedFacility]);

  useEffect(() => {
    subCategory &&
      setSubFilteredData(
        fetchedData
          .filter((e) => e.category === subCategory)[0]
          .sub_category.map((e) => ({
            name: e?.sub_category ?? "",
            value: e["CO2e"].toFixed(2),
            CO2: e["CO2"].toFixed(2),
            N2O: e["N2O"].toFixed(2),
            CH4: e["CH4"].toFixed(2),
          }))
      );
    subCategory !== "" && refSC.current?.scrollIntoView({ behavior: "smooth" });
  }, [subCategory]);

  //category
  const allDataCategory = fetchedData.map((e) => ({
    name: e?.category ?? "",
    value: e["CO2e"].toFixed(2),
    CO2: e["CO2"].toFixed(2),
    N2O: e["N2O"].toFixed(2),
    CH4: e["CH4"].toFixed(2),
  }));

  //category list
  const FacilityList = getFacility(value)
    .map(({ key }) => key)
    .filter(
      (value, index, self) => self.findIndex((item) => item === value) === index
    );
  console.log(FacilityList);
  const [filteredData, setFilteredData] = useState(allDataCategory);
  const [subFilteredData, setSubFilteredData] = useState([]);

  const getId = (prop) => {
    setSubCategory(prop);
  };

  return (
    <>
      <Card className="max-w-xxl mx-auto ">
        <Legend
          categories={filteredData.map((category) => category.name)}
        />
        <Divider />
        <TGFacility data={fetchedData} />
        <Divider />

        <div className="px-2 flex flex-row justify-between items-center">
          {/* <Title>Filters</Title> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="flex flex-row gap-4 ">
              <DatePicker
                label="From"
                className="max-w-xs bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                views={["month", "year"]}
                maxDate={dayjs(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 1,
                    1
                  )
                )}
                value={dateValueFrom}
                onChange={(newValue) => setDateValueFrom(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    InputProps: {
                      disableUnderline: true,
                      sx: { "& fieldset": { border: "none" } },
                    },
                    InputLabelProps: {
                      sx: { color: "grey" },
                    },
                  },
                }}
                sx={{ paddingX: 0.8, paddingY: 0.6 }}
              />

              <DatePicker
                label="To"
                className="max-w-xs bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                views={["month", "year"]}
                value={dateValueTo}
                onChange={(newValue) => setDateValueTo(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    InputProps: {
                      disableUnderline: true,
                      sx: { "& fieldset": { border: "none" } },
                    },
                    InputLabelProps: {
                      sx: { color: "grey" },
                    },
                  },
                }}
                sx={{ paddingX: 0.8, paddingY: 0.6 }}
              />
            </div>
          </LocalizationProvider>

          <Select
            onValueChange={setSelectedFacility}
            placeholder="Facility Selection"
            className="max-w-xs"
          >
            {FacilityList.map((facility) => (
              <SelectItem key={facility} value={facility}>
                {facility}
              </SelectItem>
            ))}
          </Select>

          <div className="hidden md:flex md:items-center md:space-x-2">
            <span
              className="shrink-0 animate-pulse rounded-tremor-full bg-emerald-500/30 p-1"
              aria-hidden={true}
            >
              <span className="block h-2 w-2 rounded-tremor-full bg-emerald-500" />
            </span>
            <p className="mt-4 text-tremor-default text-tremor-content dark:text-dark-tremor-content md:mt-0">
              Updated just now
            </p>
          </div>
        </div>
        <Divider />
        {/* Main Source */}
        <Grid numItems={1} numItemsSm={2} numItemsLg={3} className="gap-2 ">
          <TGCategory
            filteredData={filteredData}
            getId={getId}
            subCategory={subCategory}
          />
        </Grid>
        {/* Drop-Down Source */}
        {subCategory && (
          <Grid
            ref={refSC}
            numItems={1}
            numItemsSm={2}
            numItemsLg={3}
            className="gap-2 "
          >
            <TGSubCategory filteredData={subFilteredData} />
          </Grid>
        )}
      </Card>
    </>
  );
};

export default TabGraph;
