import { Box } from "@mui/material";
import { useEffect } from "react";
import { decryptData } from "../components/utils/encrypt-decrypt";

const SidebarCmpLogo = ({ open }) => {
  const url = JSON.parse(decryptData(localStorage.getItem("lsdvfsdfksmdvjkb")));

  useEffect(() => {
    // Define a function to handle the storage event
    const handleStorageChange = (e) => {
      if (e.key === "logo") {
        // The 'yourLocalStorageKey' key in localStorage was changed
        // Do something in response to the change
        console.log("LocalStorage value changed:", e.newValue);
      }
    };

    // Add the event listener to window
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: open ? "background.default" : "inherit",
          margin: 1.5,
          paddingY: 4,
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fde196",
            borderRadius: "50%",
            p: 1,
            width: "5rem",
            height: "5rem",
            overflow: "clip",
          }}
        >
          <img
            src={url}
            alt="logo"
            width="100%"
            height="100%"
            style={{ borderRadius: "50%" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default SidebarCmpLogo;
