import { useState } from "react";
import "../App.css";
import {
  Box,
  Button,
  Typography,
  keyframes,
  useMediaQuery,
} from "@mui/material";
import { decryptData } from "../components/utils/encrypt-decrypt";

const Home = ({ fullScreenProp }) => {
  // const url = getCookie("url");

  // function getCookie(cname) {
  //   let name = cname + "=";
  //   let ca = document.cookie.split(";");
  //   for (let i = 0; i < ca.length; i++) {
  //     let c = ca[i];
  //     while (c.charAt(0) == " ") {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) == 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  //   return "";
  // }
  const ismobile = useMediaQuery("(max-width:600px)");

  let url = JSON.parse(decryptData(localStorage.getItem("ddsmhfywief87we8f8")));
  const [fullScreen, setFullScreen] = useState(fullScreenProp);

  function toggleOnFullScreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;

    requestFullScreen.call(docEl);
  }

  function toggleOffFullScreen() {
    var doc = window.document;
    // var docEl = doc.documentElement;

    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    cancelFullScreen.call(doc);
  }

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    if (!fullScreen) {
      toggleOnFullScreen();
    } else {
      toggleOffFullScreen();
    }
  };

  // useEffect(() => {
  //   const keyDownHandler = (event) => {
  //     console.log("User pressed: ", event.key);

  //     //ctr+f
  //     console.log(event.ctrlKey && (event.keyCode === 70 || event.keyCode === 102));

  //     // if (event.key === "Control") {
  //     //   //   event.preventDefault();
  //     //   //   // 👇️ your logic here

  //     //   console.log("pressed Enter ✅");
  //     // }
  //   };

  //   document.addEventListener("keydown", keyDownHandler);

  //   return () => {
  //     document.removeEventListener("keydown", keyDownHandler);
  //   };
  // }, []);
  const blobBounce = keyframes`
  0% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
`;
  return (
    <>
      {ismobile ? (
        <Box
          sx={{
            top: "50%",
            left: "25%",
            position: "relative",
            width: "200px",
            height: "250px",
            borderRadius: "14px",
            zIndex: 1111,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "5px",
              left: "5px",
              width: "190px",
              height: "240px",
              zIndex: 2,
              background: "#fffffff2",
              backdropFilter: "blur(24px)",
              borderRadius: "10px",
              overflow: "hidden",
              outline: "2px solid white",
            }}
          >
            <Typography m={1} color="black">
              Dashboard Not Available in Mobile please go to Desktop or Table.
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              top: "50%",
              left: "50%",
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              backgroundColor: "#ff0000",
              opacity: 1,
              filter: "blur(12px)",
              animation: `${blobBounce} 5s infinite ease`,
            }}
          ></Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: "end", mr: 8 }}>
          <Button
            variant="contained"
            sx={{ zIndex: 1 }}
            onClick={() => {
              // callback("gdg");
              handleFullScreen();
            }}
            disableElevation
          >
            {!fullScreen ? "Full Screen" : "Exit"}
          </Button>
          <Box sx={{ ml: fullScreen ? 0 : "2.4%" }} className="par">
            <iframe
              title="Dashboard"
              width="1010"
              height="541.25"
              src={url}
              // frameBorder="0"
              allowFullScreen={true}
              // key={url}
            ></iframe>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Home;
