import { createAsyncThunk } from "@reduxjs/toolkit";

export const deviceGet = createAsyncThunk(
  "device/get",
  async ({}, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/handle/devices`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        return data.user.devices;
      } else if (res.status === 404) {
        return thunkAPI.rejectWithValue("unauthorized");
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      // const message =
      //     (error.response && error.response.data && error.response.data.message) ||
      //     error.message ||
      //     error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const deviceAdd = createAsyncThunk(
  "device/add",
  async (
    {
      deviceNickName,
      deviceGateway,
      deviceSerialNumber,
      deviceModel,
      deviceChannel,
      // lat,
      // lan,
      type,
      // facility,
      // section,
      // floor,
      // department,
      location,
      thresholdCost,
      offTime,
      lekageChannel,
    },
    thunkAPI
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/handle/devices`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            deviceNickName,
            deviceGateway,
            deviceSerialNumber,
            deviceModel,
            deviceChannel,
            type,
            location,
            thresholdCost,
            offTime,
            lekageChannel,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      //   const message =
      //     (error.response &&
      //       error.response.data &&
      //       error.response.data.message) ||
      //     error.message ||
      //     error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const deviceUpdate = createAsyncThunk(
  "device/update",
  async (
    {
      id,
      deviceNickName,
      deviceGateway,
      deviceSerialNumber,
      deviceModel,
      deviceChannel,
      type,
      location,
      thresholdCost,
      offTime,
      lekageChannel,
    },
    thunkAPI
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/handle/devices`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            id,
            deviceNickName,
            deviceGateway,
            deviceSerialNumber,
            deviceModel,
            deviceChannel,
            type,
            location,
            thresholdCost,
            offTime,
            lekageChannel,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deviceDelete = createAsyncThunk(
  "devices/del",
  async ({ id }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/handle/devices`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            id,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const trackingUpdate = createAsyncThunk(
  "device/track",
  async ({ id, trackingstatus }, thunkAPI) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/handle/devices/tracking`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            id,
            trackingstatus,
          }),
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue("error");
      }
    } catch (error) {
      // const message =
      //     (error.response && error.response.data && error.response.data.message) ||
      //     error.message ||
      //     error.toString();

      return thunkAPI.rejectWithValue("error");
    }
  }
);
