import { createAsyncThunk } from "@reduxjs/toolkit";

export const solarCompare = createAsyncThunk(
  "solar/get",
  async (
    { deviceId, year, ch, solarHours, department, forDevice, isLease, isOwn },
    thunkAPI
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL_COMPARISION}/api/v1/solar`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Content-Security-Policy": "upgrade-insecure-requests",
            //   "Content-Security-Policy": "script-src-attr 'self';"
          },
          credentials: "include",
          referrerPolicy: "unsafe_url",
          body: JSON.stringify({
            deviceId,
            year,
            ch,
            solarHours,
            department,
            forDevice,
            isLease,
            isOwn,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
