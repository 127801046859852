import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

//READ hook (get bills from api)
function useGetBills({ category }) {
  return useQuery({
    queryKey: ["bills"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/bill?category=${category}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const data = await res.json();
      return data.user_bill;
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      // return Promise.resolve(props.fakeData);
    },
    refetchOnWindowFocus: false,
  });
}

//CREATE hook (post new bil to api)
function useCreateBill({ category }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body) => {
      //send api update request here
      const {
        from,
        to,
        sub_category,
        identifier,
        consumed_value,
        cost,
        location,
      } = body;
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/bill`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            from,
            to,
            category,
            sub_category,
            identifier,
            consumed_value,
            cost,
            location,
          }),
        }
      );
      const data = await res.json();
      return data;

      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      // return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newBillInfo) => {
      queryClient.setQueryData(["bills"], (prevBills) => [
        ...prevBills,
        {
          ...newBillInfo,
        },
      ]);
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//UPDATE hook (put bill in api)
function useUpdateBill() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ values: body, id }) => {
      console.log(id);
      const {
        from,
        to,
        sub_category,
        identifier,
        consumed_value,
        cost,
        location,
      } = body;
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/bill`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            id,
            from,
            to,
            sub_category,
            identifier,
            consumed_value,
            cost,
            location,
          }),
        }
      );
      const data = await res.json();

      return data;

      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      // return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newBillInfo) => {
      queryClient.setQueryData(["bills"], (prevBils) =>
        prevBils?.map((prevBill) =>
          prevBill._id === newBillInfo._id ? newBillInfo : prevBill
        )
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete bill in api)
function useDeleteBill() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => {
      //send api update request here
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/bill`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            id,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      }

      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      // return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (id) => {
      queryClient.setQueryData(["bills"], (prevBils) =>
        prevBils?.filter((bill) => bill._id !== id)
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

function useGHGScope({ from, to, scope, facility, section }) {
  return useQuery({
    queryKey: ["billsGHG"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/bill/ghg/scope`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from,
            to,
            scope,
            facility,
            section,
          }),
          credentials: "include",
        }
      );
      const data = await res.json();
      return data.data;
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      // return Promise.resolve(props.fakeData);
    },
    refetchOnWindowFocus: false,
  });
}

function useGHGDepartment({ from, to, facility, section }) {
  return useQuery({
    queryKey: ["GHGdepartment"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/bill/ghg/department`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from,
            to,
            facility,
            section,
          }),
          credentials: "include",
        }
      );
      const data = await res.json();
      return data.data;
      // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      // return Promise.resolve(props.fakeData);
    },
    refetchOnWindowFocus: false,
  });
}

function useGHGYoY({ from, to, facility, section }) {
  return useQuery({
    queryKey: ["GHGyoy"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/bill/ghg/yoy`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from,
            to,
            facility,
            section,
          }),
          credentials: "include",
        }
      );
      const data = await res.json();
      return data.data;

    },
    refetchOnWindowFocus: false,
  });
}

export {
  useGetBills,
  useCreateBill,
  useUpdateBill,
  useDeleteBill,
  useGHGScope,
  useGHGDepartment,
  useGHGYoY
};
