import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Temperature from "../DeviceTemperature/TemperatureDeviceUpload";
import "../../App.css";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  temperatureDeviceDelete,
  temperatureDeviceGet,
} from "../../features/temperatureDeviceSlice";
import TemperatureDeviceList from "../DeviceTemperature/TemperatureDeviceList";
import NotPresent from "../NotPresent";
import SnackBarFunction from "../SnackBarFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import Sheet from "react-modal-sheet";
import ChartLayout from "../Devices/ChartLayout";
import { motion } from "framer-motion";
import "../../styles/BottonSheetButton.css";

const Sensor_Temprature = () => {
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [numSelected, setNumSelected] = useState([]);
  const [open, setOpen] = useState({ isOpen: false, dispatchType: "add" });
  const [bottomSheetOpen, setBottomSheetOpen] = useState({
    isOpen: false,
    isCard: true,
  });
  const [errors, setErros] = useState({
    loading: false,
    severity: "error",
    message: "Some Error Occured",
  });
  // const ismobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    getDevices();
  }, [open.isOpen === false]);

  useEffect(() => {
    setTimeout(() => {
      setBottomSheetOpen({ ...bottomSheetOpen, isCard: false });
    }, 1000);
    // return () => {
    //   setBottomSheetOpen({ ...bottomSheetOpen, isCard: true });
    // };
  }, [bottomSheetOpen.isCard === true]);

  const getDevices = () => {
    dispatch(temperatureDeviceGet({})).then((onResolved) => {
      if (onResolved.type === "temperatureDevice/get/fulfilled") {
        setRowsData(onResolved.payload);
      }
    });
  };

  const handleClose = () => {
    setOpen({
      ...open,
      isOpen: false,
    });
  };

  const getId = (prop) => {
    setNumSelected(prop);
  };
  return (
    <>
      <>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: 1,
            mb: 3,
          }}
        >
          <>
            <Typography
              sx={{ flex: "1 1 100%", ml: 4, fontWeight: 500 }}
              variant="h6"
              id="tableTitle"
              component="div"
              color={"#8A92A6"}
            >
              Carbonlnk Temperature Sensors
            </Typography>
            {numSelected.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  // backgroundColor: "white",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  borderRadius: 1.5,
                  marginRight: 4,
                  width: 200,
                  paddingX: 2,
                  paddingY: 1,
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  aria-label="edit"
                  size="medium"
                  onClick={() => {
                    numSelected.map(() => {
                      setOpen({ isOpen: true, dispatchType: "edit" });
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faPen} size="xs" />
                </IconButton>

                <IconButton
                  aria-label="clone"
                  size="medium"
                  onClick={() => {
                    numSelected.map(() => {
                      // handleClickOpen();
                      setOpen({ isOpen: true, dispatchType: "clone" });
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faClone} size="xs" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="medium"
                  onClick={() => {
                    numSelected.map((e) => {
                      dispatch(temperatureDeviceDelete({ id: e })).then(
                        (onResolved) => {
                          if (
                            onResolved.type ===
                            "temperatureDevice/del/fulfilled"
                          ) {
                            const id = rowsData.findIndex((i) => i._id === e);

                            rowsData.splice(id, 1);
                            setRowsData([...rowsData]);
                            setNumSelected([]);

                            setErros({
                              loading: true,
                              severity: "success",
                              message: "Device Deleted",
                            });
                            setTimeout(() => {
                              setErros({
                                ...errors,
                                loading: false,
                              });
                            }, 2000);
                          } else {
                            setErros({
                              loading: true,
                              severity: "error",
                              message:
                                onResolved.payload?.errors !== undefined
                                  ? onResolved.payload?.errors[0].message
                                  : "Some Error Occured",
                            });
                            setTimeout(() => {
                              setErros({
                                ...errors,
                                loading: false,
                              });
                            }, 2000);
                          }
                        }
                      );
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size="xs" />
                </IconButton>
              </Box>
            ) : (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                size="large"
                sx={{
                  marginRight: { xs: 2, md: 5 },
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  px: 5,
                }}
                color="mainButton"
                // disableElevation
                onClick={() => {
                  setOpen({ isOpen: true, dispatchType: "add" });
                }}
              >
                Add New
              </Button>
            )}
          </>
        </Toolbar>
        {DeviceOpen(
          open.isOpen,
          handleClose,
          rowsData,
          numSelected,
          open.dispatchType
        )}
        <SnackBarFunction
          isLoadingButton={errors.loading}
          severity={errors.severity}
          message={errors.message}
        />
      </>

      {rowsData.length <= 0 ? (
        // <Box sx={{ display: "flex", justifyContent: "center" }}>
        <NotPresent />
      ) : (
        // </Box>
        <TemperatureDeviceList rows={rowsData} getId={getId} />
      )}
      {numSelected.length > 0 &&
        BottomSheet(
          bottomSheetOpen.isOpen,
          setBottomSheetOpen,
          numSelected,
          bottomSheetOpen.isCard
        )}
    </>
  );
};

function BottomSheet(
  bottomSheetOpen,
  setBottomSheetOpen,
  numSelected,
  cardOpen
) {
  const cardVariants = {
    offscreen: {
      y: 300,
    },
    onscreen: {
      y: 50,
      rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  function Card({ emoji }) {
    return (
      <motion.div
        className="card-container box overlay"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <motion.div className="card" variants={cardVariants}>
          {emoji}
        </motion.div>
      </motion.div>
    );
  }
  return (
    <>
      <Sheet
        isOpen={bottomSheetOpen}
        onClose={() =>
          setBottomSheetOpen({ ...bottomSheetOpen, isOpen: false })
        }
        style={{
          maxWidth: "50%",
          left: "50%",
          margin: "1rem",
          borderRadius: "0.8em",
        }}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <ChartLayout numSelected={numSelected} />
          </Sheet.Content>
          <button
            onClick={() =>
              setBottomSheetOpen({ ...bottomSheetOpen, isOpen: false })
            }
          >
            Close
          </button>
        </Sheet.Container>
        <Sheet.Backdrop
          onClick={() =>
            setBottomSheetOpen({ ...bottomSheetOpen, isOpen: false })
          }
        />
      </Sheet>
      <div>
        <Button
          variant="contained"
          size="large"
          className="box"
          sx={{
            marginLeft: { xs: 2, md: 5 },
            alignItems: "center",
            whiteSpace: "nowrap",
            px: 5,
            left: { md: "60%", lg: "75%" },
            marginRight: { xs: 2, md: 5 },
          }}
          onClick={() =>
            setBottomSheetOpen({ ...bottomSheetOpen, isOpen: true })
          }
        >
          See Realtime Data
        </Button>
        {cardOpen && <Card emoji={"📈"} hueA={340} hueB={10} key={"emoji"} />};
      </div>
    </>
  );
}

function DeviceOpen(open, handleClose, rowsData, numSelected, dispatchType) {
  const id = numSelected.map((e) => {
    return rowsData.findIndex((i) => i._id === e);
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      // TransitionComponent={Transition}
    >
      <DialogTitle component="h5">
        <>
          <span className="close" onClick={handleClose}>
            X
          </span>
          <Box sx={{ marginLeft: 6 }}>
            {dispatchType === "add"
              ? "Add new Record"
              : dispatchType === "clone"
              ? "Clone Record"
              : "Edit Record"}
          </Box>
        </>
      </DialogTitle>
      <DialogContent>
        <Temperature
          rowsData={rowsData[id]}
          onClose={handleClose}
          dispatchType={dispatchType}
        />
      </DialogContent>
    </Dialog>
  );
}
export default Sensor_Temprature;
