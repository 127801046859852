import { Box } from "@mui/material";
import styles from "../styles/404.module.css";
import { useNavigate } from "react-router-dom";
const Page_404 = () => {
  const navigate = useNavigate();
  setTimeout(() => {
    navigate("/");
  }, 3000);

  return (
    <Box maxHeight="100vh" maxWidth="100vw">
      <h1 className={styles.h1}>
        4<div className={styles.shine3}></div>
        <div className={styles.shine4}></div>
      </h1>
      <h1 className={styles.h1} style={{ left: "71.2%" }}>
        4<div className={styles.shine3}></div>
        <div className={styles.shine4}></div>
      </h1>
      <div className={styles.starscontainer}>
        <div className={styles.stars}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.stars}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.stars}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.stars}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.stars}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.stars2}></div>
        <div className={styles.stars2}></div>
        <div className={styles.stars2}></div>
        <div className={styles.stars2}></div>
        <div className={styles.stars2}></div>
        <div className={styles.stars2}></div>
      </div>

      <div className={styles.moon}></div>

      <div className={styles.planetcontainer}>
        <div className={styles.planetring2}></div>
        <div className={styles.planet}></div>
        <div className={styles.shine}></div>
        <div className={styles.shine2}></div>
        <div className={styles.planetring}></div>
        <div className={styles.planetcrater}></div>
      </div>

      <div className={styles.meteorcontainer}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className={styles.meteorcontainer2}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className={styles.meteorcontainer3}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className={styles.meteorcontainer4}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <p className={styles.p1}>Page missing.</p>
      <p className={styles.p2}>We are Redirecting you to Home Page.</p>
    </Box>
  );
};

export default Page_404;
